/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call } from "redux-saga/effects";
import { PUBLIC_URL } from "../env";

import { SendError } from './send';

export function* fetchInteraction(interactionId: string) {
  const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/details`;
  const response = yield call(fetch, url);

  if (response.ok) {
    const location = response.headers.get('Location');
    const body = yield call([response, 'json']);
  
    return {
      interaction: body,
      location: location,
    }
  }

  const text = yield call([response, 'text']);
  const error = new SendError(`send failed`);
  error.statusText = response.statusText;
  error.status = response.status;
  error.body = text;
  throw error;
}