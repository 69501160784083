import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getBrowserIcon, Browsers } from 'react-browser-icons';

interface SupportedBrowser {
  type: Browsers;
  link: string;
}

const supportedBrowsers: Array<SupportedBrowser> = [
  { type: 'Chrome', link: 'https://www.google.com/chrome/' },
  { type: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/' },
  { type: 'Safari', link: 'https://support.apple.com/downloads/safari' },
  { type: 'Edge', link: 'https://www.microsoft.com/en-us/edge' },
];

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    imageContainer: {
      display: 'flex',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(7),
      },
    },
    imageItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      textDecoration: 'none',
      '&:visited': {
        color: '#fff',
        textDecoration: 'none',
      },
    },
  };
});

const renderBrowserItems = (classes: any) => {
  return supportedBrowsers.map((item: SupportedBrowser) => {
    return (
      <a target="_blank" rel="noopener noreferrer" href={item.link} key={item.type} className={classes.imageItem}>
        {getBrowserIcon({ browser: item.type, size: 90 })}
        <Typography>{item.type}</Typography>
      </a>
    );
  });
};

const UnsupportedBrowser = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h4" color="primary">
          Your browser is not supported
        </Typography>
        <Typography variant="subtitle1" color="primary">
          Please download one of the following browsers to continue
        </Typography>
      </div>
      <div className={classes.imageContainer}>{renderBrowserItems(classes)}</div>
    </div>
  );
};

export default UnsupportedBrowser;
