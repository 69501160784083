import React, { useCallback, useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    '& .MuiButton-root': {
      color: theme.palette.getContrastText('#f44336'),
    },
  },
}));

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface UxAlertProps {
  message?: string;
  action?: React.ReactNode;
  duration?: number;
}

export function UxAlert(props: UxAlertProps): JSX.Element {
  const classes = useStyles();
  const { message, action, duration } = props;
  const [open, setOpen] = useState(!!message);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useEffect(() => {
    if (!!message && !open) {
      setOpen(true);
    }
  }, [message, open]);
  return (
    <Snackbar open={open} autoHideDuration={duration || 6000} onClose={handleClose}>
      <Alert className={classes.alert} severity="error" action={action} onClose={handleClose}>
        {message}
      </Alert>
    </Snackbar>
  );
}
