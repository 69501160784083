import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { getStatus } from './selectors';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './reducer';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

export interface PalaPalaProps {
  status: string;
  onReload: () => void;
  onPause: () => void;
}

const ONE_HOUR = 60 * 60 * 1000;

const currentVersion = Number(process.env.REACT_APP_PALAPALA_VERSION || 0);

const PalaPala = () => {
  const status = useSelector(getStatus);
  const dispatch = useDispatch();
  const onPause = () => {
    const pausedUntil = new Date().getTime() + ONE_HOUR;
    dispatch(actions.wasPaused({ time: pausedUntil }));
  };
  const onReload = () => dispatch(actions.initateReload());

  useEffect(() => {
    dispatch(
      actions.begin({
        time: currentVersion,
      })
    );
  }, [dispatch]);

  const open = status === 'outdated';
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">Application Update</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          This application has been updated and is ready for you. To take advantage of the new version, click "Reload
          Now", or you can pause this message for one hour.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onPause} color="secondary">
          Pause for 1 Hour
        </Button>
        <Button onClick={onReload} color="secondary">
          Reload Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PalaPala;
