import React, { Children } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => {
  return {
    baseContainer: {
      // display: 'grid',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
      },
    },
    baseHeight: {
      [theme.breakpoints.up('md')]: {
        height: 500,
      },
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
      },
    },
    fullContainer: {
      // gridTemplateRows: '20% 65% 15%',
    },
    shortContainer: {
      // gridTemplateRows: '20% 80%',
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    message: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
    icon: {
      marginRight: 5,
      fontSize: 12,
      color: theme.palette.text.primary,
    },
    footer: {
      padding: '0px 10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});

export const InteractionStep = (props: any): React.ReactElement => {
  const classes = useStyles();

  const fullDeck = Children.count(props.children) === 3;
  const containerClassName = clsx(classes.baseContainer, props.className, {
    [classes.baseHeight]: !props.className,
    [classes.fullContainer]: fullDeck,
    [classes.shortContainer]: !fullDeck,
  });

  return (
    <Paper elevation={0} className={containerClassName}>
      {props.children}
    </Paper>
  );
};
