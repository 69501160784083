import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { InteractionAlternativesList } from '../components/InteractionAlternativesList';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { LinkButton } from '../components/LinkButton';
import { ProgressButton } from '../components/ProgressButton';

import { interactionFlowSlice } from '../data/interaction-flow';
import { InteractionSteps } from 'v3/types';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    message: {
      marginTop: 15,
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
  };
});

export const HyprDownload = (): React.ReactElement => {
  const { t } = useTranslation('v3Translations');
  const classes = useStyles();

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('hypr_download_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <div className={classes.container}>
          <Typography variant="h6" align="center" className={classes.message}>
            {t('hypr_download_message')}
          </Typography>
        </div>
        <InteractionAlternativesList>
          <LinkButton
            id="skip-hypr-registration"
            label="Skip Hypr Registration"
            action={interactionFlowSlice.actions.skipHyprRegistration}
          />
          <LinkButton
            id="use-hypr-qr-code"
            label="Register with QR Code"
            action={interactionFlowSlice.actions.hyprClientRequested}
          />
        </InteractionAlternativesList>
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.HYPR_DOWNLOAD}
          id="start-hypr-registration"
          loadingPath="interactionFlow.loading"
          action={interactionFlowSlice.actions.hyprMagicLinkRequested}
          label="Send Link"
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
