import React from 'react';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import { useSelector } from 'react-redux';

import { MFAMethods } from '../types';
import { IconText } from '../components/IconText';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';

export const MagicLinkWait = (): React.ReactElement => {
  const selectedMfaOption = useSelector((state: any) => state.interactionData.data.selectedMfaOption);

  let Icon = DevicesOutlinedIcon;
  let message = 'Please confirm the magic link that we sent to your device';

  if (selectedMfaOption === MFAMethods.SMS_MAGIC_LINK) {
    Icon = PhoneAndroidOutlinedIcon;
    message = 'Please confirm the magic link sent to your mobile device';
  }

  if (selectedMfaOption === MFAMethods.EMAIL_MAGIC_LINK) {
    Icon = MailOutlineIcon;
    message = 'Please confirm the magic link sent to your email';
  }

  return (
    <InteractionStep>
      <InteractionStepTitle title="Magic Link Confirmation" />
      <InteractionStepContent verticalCenter horizontalCenter>
        <IconText Icon={Icon}>{message}</IconText>
      </InteractionStepContent>
    </InteractionStep>
  );
};
