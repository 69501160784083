import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReauthWelcome } from '../components/Welcome';
import { reauthSlice } from '../slice';
import { IState } from '../../../store/reducers';

export const WelcomeView = (): any => {
  const dispatch = useDispatch();

  const sendNotification = useCallback(() => {
    const action = reauthSlice.actions.startHyprPush();
    dispatch(action);
  }, [dispatch]);

  const userFirstName = useSelector((state: IState) => state.reauth.details.user.firstName);
  const initials = useSelector((state: IState) => state.reauth.details.user.initials);
  const applicationName = useSelector((state: IState) => state.reauth.details.client.name);
  const message = useSelector((state: IState) => state.reauth.details.message);
  const busy = useSelector((state: IState) => state.reauth.busy);
  const logoUri = useSelector((state: IState) => state.reauth.details.logoUri);
  const error = useSelector((state: IState) => state.reauth.error);

  return (
    <ReauthWelcome
      name={userFirstName}
      initials={initials}
      application={applicationName}
      busy={busy}
      sendNotification={sendNotification}
      message={message}
      logoUri={logoUri}
      error={error}
    />
  );
};
