import React from 'react';
import { Dialog } from './Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface UxFailedInitProps {
  title: string;
}

export const UxFailedInit = (props: UxFailedInitProps): JSX.Element => {
  return (
    <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This session cannot be found. Close the browser and try again.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
