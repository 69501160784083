export enum InteractionSteps {
  USER_IDENTIFIER = 'user_identifier',
  AUTHENTICATE = 'authenticate',
  AUTHENTICATE_HYPR = 'authenticate_hypr',
  MFA_OPTIONS = 'mfa_options',
  AUTH_OPTIONS = 'auth_options',
  HYPR_DOWNLOAD = 'hypr_download',
  HYPR_QR_CODE = 'hypr_qr_code',
  HYPR_REGISTER_WAIT = 'hypr_client_register_wait',
  HYPR_SMS_REGISTRATION_WAIT = 'hypr_sms_registration_wait',
  HYPR_AUTH_WAIT = 'hypr_auth_wait',
  OTP_VERIFICATION = 'otp_verification',
  DUO_PUSH_WAIT = 'duo_push_wait',
  MAGIC_LINK_WAIT = 'magic_link_wait',
  INTERACTION_COMPLETION_WAIT = 'interaction_completion_wait',
  INTERACTION_COMPLETE = 'interaction_complete',
}

export enum InteractionErrors {
  INVALID_USER = 'invalid_user',
  INVALID_USER_DOMAIN = 'invalid_user_domain',
  AUTH_CREDENTIALS_FAILURE = 'auth_credentials_failure',
  INVALID_DUO_CONFIRMATION = 'invalid_duo_confirmation',
  MAGIC_LINK_EXPIRATION = 'magic_link_expiration',
}

export enum MFAMethods {
  SMS_OTP = 'sms_otp',
  EMAIL_OTP = 'email_otp',
  DUO_PUSH = 'duo_push',
  SMS_MAGIC_LINK = 'sms_magic_link',
  EMAIL_MAGIC_LINK = 'email_magic_link',
}
