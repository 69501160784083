/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest } from 'redux-saga/effects';
import { approveSlice } from './slice';
import { appSlice } from 'components/App/slice';

function* handleShowApprove() {
  try {
    const urlStats = new URL(window.location.href);
    const url = `${urlStats.origin}/api/${urlStats.pathname}`;

    const response: Response = yield call(fetch, url, {
      method: 'POST',
    });

    if (response.ok) {
      yield put(approveSlice.actions.approveSuccess({ message: response.statusText }));
    } else {
      yield put(approveSlice.actions.approveError({ message: response.statusText }));
    }
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

export function* watchApproveSagas() {
  yield takeLatest(approveSlice.actions.showApprove, handleShowApprove);
}
