import React from 'react';
import ClipLoader from 'react-spinners/ScaleLoader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      position: 'absolute',
      height: '100vh',
      width: '100vw',
    },
  };
});

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ClipLoader height={150} width={30} />
    </div>
  );
};
