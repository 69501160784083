import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { ProgressButton } from '../components/ProgressButton';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    qrCode: {
      height: 150,
      width: 150,
      marginBottom: 15,
    },
    message: {
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
  };
});

export const HyprQRCode = (): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('v3Translations');
  const qrCode = useSelector((state: any) => state.interactionData.data.qrCode);
  const dataUrl = `data:image/png;base64,${qrCode}`;

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('hypr_qr_code_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <div className={classes.container}>
          <img className={classes.qrCode} alt="qr-code" src={dataUrl} />
          <Typography variant="h6" className={classes.message} align="center">
            {t('hypr_qr_code_message')}
          </Typography>
        </div>
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton step="blah" loadingPath="interactionFlow.loading" />
      </InteractionStepActions>
    </InteractionStep>
  );
};
