import React, { FC } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ClipLoader from 'react-spinners/ScaleLoader';

type LoaderProps = unknown;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    position: 'absolute',
    height: '100vh',
    width: '100vw',
  },
});

export const Loader: FC<LoaderProps> = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <ClipLoader height={150} width={30} color={theme.palette.primary.main} />
    </div>
  );
};
