import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { themeSlice } from './slice';

const useStyles = makeStyles(() => {
  return {
    select: {
      paddingLeft: 7,
      paddingRight: 7,
      fontSize: 14,
    },
  };
});

export const ThemeSelector: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeMode = useSelector((state: any) => state.theme.mode);

  const handleChange = (event: any) => {
    dispatch(themeSlice.actions.changeTheme({ mode: event.target.value }));
  };

  return (
    <Select
      disableUnderline
      classes={{ select: classes.select }}
      id="theme-mode-selector"
      value={themeMode}
      onChange={handleChange}
    >
      <MenuItem value="light">Light Theme</MenuItem>
      <MenuItem value="dark">Dark Theme</MenuItem>
    </Select>
  );
};
