import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *:last-child': {
        marginTop: -6,
        marginBottom: 10,
      },
    },
  };
});

export const IconGroup = (props: any): React.ReactElement => {
  const classes = useStyles();

  return <div className={classes.container}>{props.children}</div>;
};
