import React, { useCallback } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IState } from 'store/reducers';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    textAlign: 'center',
  },
  errorMessage: {
    alignSelf: 'center',
  },
  margin: {},
  button: {},
}));

export const Error = () => {
  const error_message = useSelector(
    (state: IState) => state.passwordless.interaction && state.passwordless.interaction.error_description
  );
  const classes = useStyles({});
  const startUri = useSelector(
    (state: IState) => state.passwordless.interaction && state.passwordless.interaction.startUri
  );

  const handlePressSubmit = useCallback(() => {
    if (!startUri) return;
    window.location.href = encodeURI(startUri);
  }, [startUri]);

  if (!error_message) return null;

  return (
    <>
      <Typography className={classes.title} dangerouslySetInnerHTML={{ __html: error_message }}></Typography>
      {startUri && (
        <Button className={clsx(classes.margin, classes.button)} onClick={handlePressSubmit}>
          Start Over
        </Button>
      )}
    </>
  );
};
