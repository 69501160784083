import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { UxLogin } from './UxLogin';
import { IState } from '../../../store/reducers';
import { loginSlice } from './slices';
import { Loader } from 'components/SessionWall/Loader';

export const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { form, options } = useSelector((state: IState) => state.login);
  const { username, password, status, domain, errorMessage } = form;
  const domains = options?.domains;

  const {
    loginHint,
    loginMessage,
    forgotPasswordLink,
    landingLinks,
    readOnlyUsername,
    notAccountHolderUrl,
    accountDomain,
  } = useSelector((state: IState) => {
    const { loginHint, loginMessage, forgotPasswordLink, landingLinks, readOnlyUsername, notAccountHolderUrl, accountDomain } =
      state.interaction.interaction || {};
    return {
      loginHint,
      loginMessage,
      forgotPasswordLink,
      landingLinks,
      readOnlyUsername,
      notAccountHolderUrl,
      accountDomain,
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(loginSlice.actions.showLogin());
  }, [dispatch]);

  const handlePressLogin = useCallback(() => {
    const action = loginSlice.actions.pressLogin({
      username,
      password,
    });
    dispatch(action);
  }, [dispatch, username, password]);

  const handleChangeValue = useCallback((key: string, value: string) => {
    if (key === 'domain') {
      localStorage.setItem('previous-domain', value);
    }
    const action = loginSlice.actions.changeValues({ [key]: value });
    dispatch(action);
  }, [dispatch]);

  useEffect(() => {
    const values: { [key: string]: unknown } = {};

    if (!domain && accountDomain) {
      if (domains?.includes(accountDomain)) {
        values.domain = accountDomain;
      }
    }

    if (!domain && !accountDomain) {
      const firstDomain = domains && domains[0];
      let previousDomain = localStorage.getItem('previous-domain');
      previousDomain = domains && domains.includes(previousDomain || '') ? previousDomain : null;
      const defaultDomain = previousDomain || firstDomain;
      values.domain = defaultDomain;
    }

    if (!!loginHint) {
      values.username = loginHint;
    }

    if (Object.keys(values)) {
      const action = loginSlice.actions.changeValues(values);
      dispatch(action);
    }
  }, [domains, accountDomain, dispatch, loginHint, domain]);

  if (!options) {
    return <Loader />;
  }

  return (
    <UxLogin
      loginHint={loginHint}
      logoUri={options.logoUri}
      title={options?.clientName}
      errorMessage={errorMessage}
      message={loginMessage || options?.reasonDescription}
      status={status}
      username={username}
      password={password}
      domain={domain}
      domains={domains}
      forgotPasswordLink={forgotPasswordLink}
      landingLinks={landingLinks}
      readOnlyUsername={readOnlyUsername}
      notAccountHolderUrl={notAccountHolderUrl}
      onPressLogin={handlePressLogin}
      onChangeValue={handleChangeValue}
    />
  );
};

export default Login;
