import { takeLatest, put, select, cancel } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import { interactionFlowSlice } from './slice';
import { interactionDataSlice } from '../interaction-data';
import { getApiCall, getFlowResult } from '../utils';
import get from 'lodash/get';
import { InteractionSteps } from '../../types';

/*********************************************************
 * CORE INTERACTION FLOW SAGAS
 *********************************************************/
const getInteractionId = (state: any) => {
  return state.v3interaction.interactionId || state.v3interaction.details.uuid;
};

const getCurrentStep = (state: any) => {
  return state.interactionFlow.currentStep;
};

function* onFlowStepSuccess() {
  const interactionId = yield select(getInteractionId);
  const currentStep = yield select(getCurrentStep);

  const nextPath = `/v3/interaction/${interactionId}/${currentStep}`;

  yield put(push(nextPath));
}

function* onAuthOptionChanged() {
  const interactionId = yield select(getInteractionId);
  const currentStep = yield select(getCurrentStep);

  const nextPath = `/v3/interaction/${interactionId}/${currentStep}`;

  yield put(replace(nextPath));
}

export function* watchFlowStepSuccess() {
  yield takeLatest(interactionFlowSlice.actions.flowStepSuccess, onFlowStepSuccess);
}

export function* watchAuthOptionChanged() {
  yield takeLatest(interactionFlowSlice.actions.authOptionChanged, onAuthOptionChanged);
}

/*********************************************************
 * USER & PASSWORD SAGAS
 *********************************************************/
function* onUserAuthOptionsRequested() {
  const username = yield select((state: any) => state.interactionData.data.userIdentifier);
  const authUsername = yield select((state: any) => get(state, 'interactionFlow.data.authOptionsUsername'));

  if (username === authUsername) {
    yield put(interactionFlowSlice.actions.notLoading());
    yield cancel();
  }

  const result = yield getApiCall('user.authOptions');
  yield put(interactionFlowSlice.actions.notLoading());
  yield put(interactionFlowSlice.actions.setData({ key: 'interactionFlow.data.authOptions', value: result.options }));
  yield put(
    interactionFlowSlice.actions.setData({ key: 'interactionFlow.data.authOptionsUsername', value: result.username })
  );
}

function* onUserValidationRequested() {
  const userIdentifier = yield select((state: any) => state.interactionData.data.userIdentifier);
  yield getFlowResult('user.validate', userIdentifier);
}

function* onPasswordAuthenticationRequested() {
  const password = yield select((state: any) => state.interactionData.data.password);
  yield getFlowResult('user.authenticate', password);
}

export function* watchUserAuthOptionsRequested() {
  yield takeLatest(interactionFlowSlice.actions.userAuthOptionsRequested, onUserAuthOptionsRequested);
}

export function* watchUserValidationRequested() {
  yield takeLatest(interactionFlowSlice.actions.userValidationRequested, onUserValidationRequested);
}

export function* watchPasswordAuthenticationRequested() {
  yield takeLatest(interactionFlowSlice.actions.passwordAuthenticationRequested, onPasswordAuthenticationRequested);
}

/*********************************************************
 * HYPR PASSWORDLESS SAGAS
 *********************************************************/
function* onHyprClientRequested() {
  yield put(interactionFlowSlice.actions.removeStep({ step: InteractionSteps.HYPR_DOWNLOAD }));
  yield put(interactionFlowSlice.actions.removeStep({ step: InteractionSteps.HYPR_SMS_REGISTRATION_WAIT }));
  yield put(interactionFlowSlice.actions.addStep({ step: InteractionSteps.HYPR_QR_CODE }));

  const result = yield getApiCall('hypr.generateQRCode');
  yield put(interactionDataSlice.actions.setData({ key: 'interactionData.data.qrCode', value: result.data.qrCode }));
  yield put(interactionFlowSlice.actions.flowStepSuccess(result));

  yield put(interactionFlowSlice.actions.loading());
  yield getFlowResult('hypr.registerClient');
}

function* onHyprMagicLinkRequested() {
  yield put(interactionFlowSlice.actions.nextStepRequested());
  yield getFlowResult('hypr.sendMagicLink');
}

function* onHyprAuthenticationRequested() {
  yield put(interactionFlowSlice.actions.nextStepRequested());
  yield getFlowResult('hypr.authenticate');
}

export function* watchHyprClientRequested() {
  yield takeLatest(interactionFlowSlice.actions.hyprClientRequested, onHyprClientRequested);
}

export function* watchHyprMagicLinkRequested() {
  yield takeLatest(interactionFlowSlice.actions.hyprMagicLinkRequested, onHyprMagicLinkRequested);
}

export function* watchHyprAuthenticationRequested() {
  yield takeLatest(interactionFlowSlice.actions.hyprAuthenticationRequested, onHyprAuthenticationRequested);
}

/*********************************************************
 * MFA SAGAS
 *********************************************************/
function* onMfaOptionsRequested() {
  const result = yield getApiCall('mfa.getOptions');
  yield put(interactionFlowSlice.actions.notLoading());
  yield put(interactionFlowSlice.actions.setData({ key: 'interactionFlow.data.mfaOptions', value: result.options }));

  yield put(
    interactionDataSlice.actions.setData({
      key: 'interactionData.data.selectedMfaOption',
      value: result.options[0].key,
    })
  );
}

function* onMfaStartRequested() {
  const selectedMfaOption = yield select((state: any) => state.interactionData.data.selectedMfaOption);
  const result = yield getFlowResult('mfa.start', selectedMfaOption);

  const shouldWait = get(result, 'data.wait');
  if (shouldWait) {
    yield getFlowResult('mfa.wait', selectedMfaOption);
  }
}

function* onMfaVerificationRequested() {
  const verificationCode = yield select((state: any) => state.interactionData.data.verificationCode);
  yield getFlowResult('mfa.verify', verificationCode);
}

export function* watchMfaOptionsRequested() {
  yield takeLatest(interactionFlowSlice.actions.mfaOptionsRequested, onMfaOptionsRequested);
}

export function* watchMfaStartRequested() {
  yield takeLatest(interactionFlowSlice.actions.mfaStartRequested, onMfaStartRequested);
}

export function* watchMfaVerificationRequested() {
  yield takeLatest(interactionFlowSlice.actions.mfaVerificationRequested, onMfaVerificationRequested);
}
