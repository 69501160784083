export class HttpError extends Error {
  status: number;
  statusText: string;
  body: string;
  private constructor(message: string, response: Response) {
    super(message);
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = '';
  }

  static async fromResponse(message: string, response: Response): Promise<HttpError> {
    const error = new HttpError(message, response);
    error.body = await response.text();
    return error;
  }

  toString = (): string => {
    return `${this.message}: status = ${this.status} statusText = ${this.statusText} body = ${this.body}`;
  };
}
