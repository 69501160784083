import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  };
});

export const InteractionAlternativesList = (props: any) => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};
