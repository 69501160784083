import { IState } from 'store/reducers';
import { createSelector } from 'reselect';

const sessionState = (state: IState) => state.session;

/// This is not a "selector" on purpose. Check every time.
export const timeUntilExpiration = (state: IState) => {
  const session = state.session;

  if (!session.ticket) return -1;

  const timestamp = session.ticket.exp;

  return timestamp * 1000 - new Date().getTime();
};

/// This is not a "selector" on purpose. Check every time.
export const isExpired = (state: IState) => {
  const userInfo = state.session;

  if (!userInfo.ticket) return true;

  return timeUntilExpiration(state) <= 0;
};

export const isUserAuthenticated = createSelector(sessionState, isExpired, (userInfo, expired) => {
  if (!userInfo.ticket) return false;
  return !expired;
});

export const getUserDetails = createSelector(sessionState, (userInfo) => userInfo.user);
