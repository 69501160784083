import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { Dialog } from '../../Anonymous/Dialog/Dialog';
import { ProgressButton } from '../../ProgressButton/ProgressButton';

const useStyles = makeStyles({
  container: {
    minWidth: 320,
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    padding: '8px',
    paddingTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: '64px',
    width: '64px',
    borderRadius: '50%'
  },
  message: {
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hyprButton: {
    marginTop: '14px',
  },
  actionBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export interface NotificationPromptProps {
  disabled?: boolean;
  sendNotification: any;
  busy: boolean;
}

export const ReauthNotificationPrompt = (props: NotificationPromptProps) => {
  const classes = useStyles();

  return (
    <Dialog open={true}>
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          <img src={`${process.env.PUBLIC_URL}/miauth.svg`} className={classes.icon} />
        </div>
        <div className={classes.message}>
          <Typography align="center">
            We have sent a miAuth notification to your phone. Please approve it to continue
          </Typography>
          <div className={classes.actionBar}>
            <ProgressButton
              className={classes.hyprButton}
              onClick={props.sendNotification}
              loading={props.busy}
              success={false}
              disabled={props.disabled}
            >
              Resend Notification
            </ProgressButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
