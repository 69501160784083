import { createSlice, PayloadAction } from 'redux-starter-kit';

export interface ErrorPayload {
  error: Error;
}
export const appSlice = createSlice({
  slice: 'app',
  initialState: {},
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    sagaError: (_state, _action: PayloadAction<ErrorPayload>): void => {},
  },
});
