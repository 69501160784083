import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Loader } from './Loader';
import Notifier from '../data/notifications/Notifier';
import { useRouteMatch } from 'react-router';

import { MainOptions } from '../components/MainOptions';
import { Interaction } from '../Interaction';
import { interactionSlice } from '../data/interaction';

const useStyles = makeStyles(() => {
  return {
    container: {
      position: 'relative',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

export const InteractionWall = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const loading = useSelector((state: any) => state.v3interaction.loading);
  const interactionId = (match.params as any).interactionId;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    dispatch(interactionSlice.actions.interactionDetailsRequested({
      interactionId: interactionId
    }));
  }, [dispatch]);

  if (!loading && !interactionId) {
    return <div>No Interaction</div>;
  }

  return (
    <React.Fragment>
      <Notifier />
      <div className={classes.container}>
        {loading && <Loader />}
        <Interaction />
        {!isSmallDevice && <MainOptions />}
      </div>
    </React.Fragment>
  );
};
