import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { OptionModel } from './slices';
import { UxNoMfaOptionsFound } from './UxNoMfaOptionsFound';
import { ProgressButton } from '../../ProgressButton/ProgressButton';
import { Dialog } from '../Dialog/Dialog';
import { UxAlert } from './UxAlert';

export interface UxMultiFactorProps {
  busy: boolean;
  options: OptionModel[] | null;
  message: string;
  title?: string;
  selectedIndex: number;
  errorMessage?: string;
  onChange: (value: string) => void;
  onPressNextStep: () => void;
  onPressCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
    margin: 0,
  },
  nextStep: {
    height: '2rem',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
  },
  cancelButton: {
    color: 'red'
  }
}));

export const UxMultiFactor = (props: UxMultiFactorProps): JSX.Element | null => {
  const {
    errorMessage,
    options,
    message,
    title,
    selectedIndex,
    onChange,
    onPressNextStep,
    onPressCancel,
    busy,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = useCallback((e: React.ChangeEvent<{}>) => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  }, [onChange]);

  if (!options) return null;
  if (options.length === 0) return <UxNoMfaOptionsFound />;
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        id="multi-factor-options-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.container,
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
          <RadioGroup aria-label="ringtone" name="ringtone" onChange={handleChange} value={options[selectedIndex].key}>
            {options.map((option) => (
              <FormControlLabel
                id={option.key}
                value={option.key}
                key={option.key}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
          <br />
          <DialogContentText id="alert-option-description" className={classes.nextStep}>
            {options[selectedIndex].nextStep}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonContainer}>
            <Button className={classes.cancelButton} id="multi-factor-cancel" color="primary" onClick={onPressCancel}>
              Cancel
            </Button>
            <ProgressButton
              id="multi-factor-next-step"
              color="primary"
              onClick={onPressNextStep}
              loading={busy}
              success={false}
            >
              {options[selectedIndex].buttonText}
            </ProgressButton>
          </div>
        </DialogActions>
        <UxAlert message={errorMessage} action={<Button onClick={onPressNextStep}>Retry</Button>} />
      </Dialog>
    </>
  );
};
