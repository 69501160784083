import { sendInteraction } from '../send';

export const authOptions = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/user/auth/options');
  return result;
};

export const validate = async (interactionId: string, userIdentifier: string) => {
  const result = await sendInteraction(interactionId, '/user/validate', {
    method: 'POST',
    body: { userIdentifier },
  });

  return result;
};

export const authenticate = async (interactionId: string, password: string) => {
  const result = await sendInteraction(interactionId, '/user/authenticate', {
    method: 'POST',
    body: { password },
  });

  return result;
};
