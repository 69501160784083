import React from 'react';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '../components/LinkButton';
import { HyprIcon } from '../components/HyprIcon';
import { IconText } from '../components/IconText';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionAlternativesList } from '../components/InteractionAlternativesList';
import { InteractionStepContent } from '../components/InteractionStepContent';

import { interactionFlowSlice } from '../data/interaction-flow';

export const HyprAuthWait = (): React.ReactElement => {
  const { t } = useTranslation('v3Translations');

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('hypr_auth_wait_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <IconText Icon={HyprIcon} SecondaryIcon={PhoneIcon}>
          {t('hypr_auth_wait_message')}
        </IconText>
        <InteractionAlternativesList>
          <LinkButton
            id="resend-hypr-auth"
            label="Resend Hypr Auth Request"
            action={interactionFlowSlice.actions.hyprAuthenticationRequested}
          />
        </InteractionAlternativesList>
      </InteractionStepContent>
    </InteractionStep>
  );
};
