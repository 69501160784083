import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from '../../SessionWall/Loader';
import { WelcomeView } from './Welcome';
import { NotificationPromptView } from './NotificationPrompt';
import { reauthSlice } from '../slice';

export const ReauthInteractionWall = () => {
  const params: any = useParams();
  const dispatch = useDispatch();

  const view = useSelector((state: any) => state.reauth.view);
  const loaded = useSelector((state: any) => state.reauth.loaded);

  useEffect(() => {
    const interactionId = params.interactionId;
    const action = reauthSlice.actions.loadInteraction({ interactionId });
    dispatch(action);
  }, []);

  if (!loaded) {
    return <Loader />;
  }

  const ViewComponent = view === 'welcome' ? WelcomeView : NotificationPromptView;

  return (
    <React.Fragment>
      <ViewComponent />
    </React.Fragment>
  );
};
