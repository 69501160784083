import { createSlice, PayloadAction } from 'redux-starter-kit';
import { FormStatus } from 'components/Anonymous/Login/slices';

const initialState = {
  status: 'busy' as FormStatus,
  message: undefined as string | undefined,
};

export interface ApprovePayload {
  message: string;
}

export const approveSlice = createSlice({
  slice: 'approveSlice',
  initialState,
  reducers: {
    showApprove: function (draft) {
      draft.status = 'busy';
      draft.message = 'Approving Login';
    },
    approveSuccess: function (draft, action: PayloadAction<ApprovePayload>) {
      draft.status = 'success';
      draft.message = action.payload.message;
    },
    approveError: function (draft, action: PayloadAction<ApprovePayload>) {
      draft.status = 'error';
      draft.message = action.payload.message;
    },
  },
});
