import fetch from "isomorphic-fetch";
import { PUBLIC_URL } from "../../../env";

const buildUrl = (segment: string, interactionId: string) => {
  return `${PUBLIC_URL}/iapi/reauth/interaction/${interactionId}/${segment}`;
};

const reauthFetch = async (options: any) => {
  const url = buildUrl(options.segment, options.interactionId);
  const response = await fetch(url, options.fetch);

  if (!response.ok) {
    const error = new Error("ReauthFetchError");
    (error as any).headers = response.headers;

    try {
      const json = await response.json();
      (error as any).error_message = json.error_message;
    } catch (error) {
      (error as any).error_message = "An Error Occurred";
    }

    throw error;
  }

  const json = await response.json();

  return {
    data: json,
    headers: response.headers,
  };
};

export const getInteraction = async (interactionId: string) => {
  return await reauthFetch({
    segment: "details",
    interactionId: interactionId,
  });
};

export const hyprPush = async (interactionId: string) => {
  return await reauthFetch({
    segment: "hypr_push",
    interactionId: interactionId,
    fetch: { method: "POST" },
  });
};

export const hyprWait = async (interactionId: string) => {
  return await reauthFetch({
    segment: "hypr_wait",
    interactionId: interactionId,
    fetch: { method: "POST" },
  });
};
