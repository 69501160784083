import { createSlice } from "@reduxjs/toolkit";
import { interactionPattern } from "../utils";

interface InteractionState {
  loading: boolean;
  interactionId: string | null;
  details: any;
}

const initialState: InteractionState = {
  loading: false,
  interactionId: null,
  details: null,
};

export const interactionSlice = createSlice({
  name: "interaction",
  initialState: initialState,
  reducers: {
    interactionDetailsRequested: (draft: InteractionState, action: any) => {
      if (action.payload.interactionId) {
        draft.interactionId = action.payload.interactionId;
      }

      draft.loading = true;
    },
    interactionDetailsReceived: (draft: InteractionState, action: any) => {
      draft.loading = false;
      draft.details = action.payload.details;
    },
    interactionCompletionRequested: (draft: InteractionState) => {
      draft.loading = false;
    },
    interactionComplete: (draft: InteractionState) => {
      draft.loading = false;
    },
  },
  extraReducers: {
    "@@router/LOCATION_CHANGE": (draft: InteractionState, action: any) => {
      const match = interactionPattern.match(action.payload.location.pathname);

      if (match && match.interactionId) {
        draft.interactionId = match.interactionId;
      }
    },
  },
});
