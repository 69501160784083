import { createSlice } from '@reduxjs/toolkit';

interface NotificationState {
  notifications: Array<{ key: string }>;
}

const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    enqueueSnackbar: (draft, action) => {
      draft.notifications.push(action.payload);
    },
    removeSnackbar: (draft, action) => {
      draft.notifications.filter((notification) => notification.key !== action.payload.key);
    },
  },
});
