import fetch from "isomorphic-fetch"

export default async () => {
  const response = await fetch("/__meta/version.json");
  if (!response.ok) {
    return 0;
  }
  const { version } = await response.json();
  return version;
};
