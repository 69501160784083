import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

export const Username = PersonIcon;
export const ShowPassword = VisibilityIcon;
export const HidePassword = VisibilityOffIcon;
export const Password = VpnKeyIcon;
export const Phone = PhoneAndroidIcon;
