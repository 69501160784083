import { PUBLIC_URL } from "env";
import React, { useCallback, useEffect } from 'react';
import { UxHyprPush } from './UxHyprPush';
import { multiFactorSlice } from '../MultiFactor/slices';
import { useDispatch } from 'react-redux';
import useEventSource from 'use-event-source'
import { useParams } from 'react-router-dom';

export const HyprPush = (): JSX.Element => {
  const { interactionId } = useParams<any>();
  const url = `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/mfa/v2/hypr_status`
  const dispatch = useDispatch();

  useEffect(() => {
    const es = new EventSource(url);

    es.addEventListener('message', (message: any) => {
      const data = JSON.parse(message.data);

      if (data.location) {
        window.location.href = data.location;
        es.close();
      }
    })

    es.addEventListener('error', (error: any) => {
      console.log(error);
    })

  

  }, []);

  const title = 'Waiting for Approval';

  const handlePressCancel = useCallback(() => {
    const action = multiFactorSlice.actions.pressCancel();
    dispatch(action);
  }, [dispatch]);

  return <UxHyprPush title={title} onPressCancel={handlePressCancel} />;
};
