import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';

const useStyles = makeStyles(() => {
  return {
    field: {
      textAlign: 'left',
      fontFamily: 'monospace',
      fontSize: '2.5rem',
      padding: '0.8rem',
      paddingTop: '.25rem',
      paddingBottom: '.25rem',
      width: '6ch',
    },
  };
});

interface SimpleVerificationCodeProps {
  code: string;
  onChange: (code: string) => void;
}

export const SimpleVerificationCode = (props: SimpleVerificationCodeProps): JSX.Element => {
  const { code, onChange } = props;
  const classes = useStyles();

  return (
    <OutlinedInput
      id="otp-verification"
      classes={{ input: classes.field }}
      autoFocus
      value={code || ''}
      inputProps={{
        autoComplete: 'one-time-code'
      }}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
