import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { ProgressButton } from '../components/ProgressButton';
import { RadioSelect } from '../components/RadioGroup';

import { InteractionSteps } from '../types';
import { interactionDataSlice } from '../data/interaction-data';
import { interactionFlowSlice } from '../data/interaction-flow';
import { useApi, useRadio } from '../hooks';

const useStyles = makeStyles((theme: Theme) => {
  return {
    nextStep: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

export const MFAOptions = (): React.ReactElement => {
  const classes = useStyles();
  useApi(interactionFlowSlice.actions.mfaOptionsRequested);

  const { options, key, currentValue, onChange } = useRadio(
    'interactionFlow.data.mfaOptions',
    'interactionData.data.selectedMfaOption',
    interactionDataSlice.actions.setData,
    (options: Array<any>, key: string) => options.find((x) => x.key === key)
  );

  const buttonText = currentValue && currentValue.buttonText;

  return (
    <InteractionStep>
      <InteractionStepTitle title="Multi Factor Options" />
      <InteractionStepContent verticalCenter>
        <RadioSelect id="mfa-options" options={options} value={key} onChange={onChange} />
        {currentValue && <Typography className={classes.nextStep}>{currentValue.nextStep}</Typography>}
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.MFA_OPTIONS}
          id="submit-mfa-option"
          requiredPaths={['interactionData.data.selectedMfaOption']}
          loadingPath="interactionFlow.loading"
          action={interactionFlowSlice.actions.mfaStartRequested}
          label={buttonText || 'Next'}
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
