import { put, takeLatest, call, delay, select, cancel } from 'redux-saga/effects';
import { actions, Ticket } from './reducer';
import { isExpired } from './selectors';
import validator from 'validator';

const apiFetchTicket = async () => {
  const type = 'basic';
  const deleteFl = false; //type === 'private';
  const response = await fetch(`/${type}/userinfo?delete=${deleteFl}`, { credentials: 'include' });
  if (response.status === 401) {
    const location = response.headers.get('Location');
    if (location) {
      window.location.href = encodeURI(location);
      return { error: 'refreshing session' };
    }
    return { error: 'refreshing session' };
  }
  if (response.ok) {
    const ticket: Ticket = await response.json();
    return { ticket };
  } else return { error: response.status };
};

function* fetchMyInfoAndTicket() {
  const { ticket, error: ticketError } = yield call(apiFetchTicket);
  if (ticketError) {
    yield cancel();
    return;
  }
  yield put(actions.setSession(ticket));
}

export function* watchFetchMe() {
  yield takeLatest(actions.fetchMe.type, fetchMyInfoAndTicket);
}

export function* watchForSessionExpiration() {
  yield takeLatest(actions.setSession.type, function* () {
    while (true) {
      yield delay(15000);
      const needToInvalidate = yield select(isExpired);
      if (needToInvalidate) {
        yield put(actions.revoke());
      }
    }
  });
}
