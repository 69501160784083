import { PayloadAction, createSlice } from 'redux-starter-kit';

export type Ticket = {
  access_token: string;
  at_hash: string;
  aud: string;
  department_name: string;
  division_name: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  misoo_token: string;
  misoo_token_expire_at: string;
  mobile_integration: boolean;
  name: string;
  sub: string;
  ticket_name: string;
  worker_id: number;
};

export type User = {
  departmentName: string;
  divisionName: string;
  firstName: string;
  lastName: string;
  username: string;
  workerId: number;
};

const initialState: SessionState = {
  loading: false,
  mobileIntegration: false,
  ticket: null,
  user: null,
};

type SessionState = {
  loading: boolean;
  mobileIntegration: boolean;
  ticket: null | Ticket;
  user: null | User;
};

const sessionSlice = createSlice({
  slice: 'session',
  initialState,
  reducers: {
    fetchMe(state) {
      state.loading = true;
    },
    setSession(state, action: PayloadAction<Ticket>) {
      state.loading = false;
      state.ticket = action.payload;
      state.user = {
        departmentName: action.payload.department_name,
        divisionName: action.payload.division_name,
        firstName: action.payload.given_name,
        lastName: action.payload.family_name,
        username: action.payload.sub,
        workerId: action.payload.worker_id,
      } as User;
      state.mobileIntegration = action.payload.mobile_integration;
    },
    revoke(state) {
      state.user = null;
      state.ticket = null;
    },
  },
});

export const actions = sessionSlice.actions;

export const reducer = sessionSlice.reducer;
