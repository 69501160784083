import React, { useCallback } from 'react';
import { UxDevice } from './UxDevice';
import { loginSlice } from '../Login/slices';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../store/reducers';

export const NewDevice = (): JSX.Element => {
  const dispatch = useDispatch();
  const logoUri = useSelector((state: IState) => state.interaction?.interaction?.logoUri);

  const handleContinueLogin = useCallback(() => {
    const action = loginSlice.actions.continueLogin();
    dispatch(action);
  }, [dispatch]);

  return <UxDevice onContinueLogin={handleContinueLogin} logoUri={logoUri} />;
};