import React, { memo } from 'react';
import { SMSEntryUx } from './SMSEntryUx';
import { useDispatch, useSelector } from 'react-redux';
import { passwordlessSlice } from './slice';
import { IState } from 'store/reducers';
import get from 'lodash/get';

const defaultMessage = 'Please enter your phone number (vendor) or username (EOG Employee)';

export const SMSEntry = memo(() => {
  const message = useSelector((state: IState) => get(state, 'passwordless.interaction.loginMessage', defaultMessage));
  const phoneNumber = useSelector((state: IState) => state.passwordless.params.phoneNumber);
  const loading = useSelector((state: IState) => state.passwordless.loading);
  const errorMessage = useSelector((state: IState) => state.passwordless.errorMessage);
  const dispatch = useDispatch();

  const handleChangeValue = (key: string, value: string): void => {
    const action = passwordlessSlice.actions.changeValue({
      key,
      value,
    });
    dispatch(action);
  };

  const handlePressSubmit = (): void => {
    const action = passwordlessSlice.actions.sendCode();
    dispatch(action);
  };

  return (
    <SMSEntryUx
      errorMessage={errorMessage}
      message={message}
      phoneNumber={phoneNumber}
      onPressSubmit={handlePressSubmit}
      onChangeValue={handleChangeValue}
      loading={loading}
    />
  );
});
