import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { passwordlessSlice } from './slice';
import { CodeVerifyUx } from './CodeVerifyUx';
import { IState } from 'store/reducers';

export const CodeVerify = memo(() => {
  const message = useSelector((state: IState) => state.passwordless.verify && state.passwordless.verify.message);
  const loading = useSelector((state: IState) => state.passwordless.loading);
  const errorMessage = useSelector((state: IState) => state.passwordless.errorMessage);

  const dispatch = useDispatch();
  const handleChangeValue = (key: string, value: string): void => {
    const action = passwordlessSlice.actions.changeValue({
      key,
      value,
    });
    dispatch(action);
  };
  const handlePressSubmit = (): void => {
    const action = passwordlessSlice.actions.verifyCode();
    dispatch(action);
  };

  const handlePressDidNotGetCode = (): void => {
    const action = passwordlessSlice.actions.didNotGetCode();
    dispatch(action);
  };

  if (!message) {
    return null;
  }

  return (
    <CodeVerifyUx
      errorMessage={errorMessage}
      message={message}
      onPressSubmit={handlePressSubmit}
      onChangeValue={handleChangeValue}
      onPressDidNotGetCode={handlePressDidNotGetCode}
      loading={loading}
    />
  );
});

CodeVerify.displayName = 'CodeVerify';