import { call, put, takeLatest, select } from "redux-saga/effects";

import * as api from "./api";
import { reauthSlice } from "./slice";
import { IState } from "../../store/reducers";

function* getInteraction(payload: any): Generator<any> {
  let response: any;

  try {
    response = yield call(
      api.getInteraction as any,
      payload.payload.interactionId
    );
    yield put(reauthSlice.actions.loadInteractionSuccess(response.data));
  } catch (error) {
    yield put(reauthSlice.actions.loadInteractionFailure(response.data));
  }
}

function* hyprPush(): Generator<any> {
  let response: any;

  try {
    const interactionId = yield select(
      (state: IState) => state.reauth.interactionId
    );
    response = yield call(api.hyprPush as any, interactionId);
    yield put(reauthSlice.actions.hyprPushSuccess(response.data));
  } catch (error) {
    yield put(
      reauthSlice.actions.hyprPushFailure({
        error: (error as any).error_message,
      })
    );
    const location = (error as any).headers.get("Location");

    if (location) {
      window.location.href = location;
    }
  }
}

function* hyprWait(): Generator<any> {
  try {
    const interactionId = yield select(
      (state: IState) => state.reauth.interactionId
    );
    const response: any = yield call(api.hyprWait as any, interactionId);

    const location = response.headers.get("Location");

    if (location) {
      window.location.href = location;
      return;
    }
  } catch (error) {
    yield put(reauthSlice.actions.hyprConfirmationFailure());
  }
}

export function* watchGetInteraction() {
  yield takeLatest(reauthSlice.actions.loadInteraction, getInteraction);
}

export function* watchHyprPush() {
  yield takeLatest(reauthSlice.actions.startHyprPush, hyprPush);
}

export function* watchHyprWait() {
  yield takeLatest(reauthSlice.actions.waitForHyprConfirmation, hyprWait);
}
