import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Theme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import makeBaseTheme from './create-base-mui-theme';
import { darkTheme } from './dark-theme';
import { lightTheme } from './light-theme';
import { geodeColors } from '@eog/geode-colors';

import darkBackgroundImage from './background-dark.jpg';
import lightBackgroundImage from './background-light.jpg';

export interface ExtendedTheme extends Theme {
  layout?: {
    vh: (
      percent: number,
      options?: {
        subtract: number[];
      }
    ) => number;
  };
}

export interface ThemeProviderProps {
  children?: ReactNode;
}

const ThemeProvider: FunctionComponent<ThemeProviderProps> = (props: any) => {
  const themeMode = useSelector((state: any) => state.theme.mode);

  const theme = useMemo(() => {
    const isDarkMode = themeMode === 'dark';
    const backgroundImage = isDarkMode ? darkBackgroundImage : lightBackgroundImage;

    return makeBaseTheme({
      overrides: {
        MuiCssBaseline: {
          '@global': {
            body: {
              backgroundColor: 'transparent',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${backgroundImage})`,
              backgroundBlendMode: 'overlay',
            },
          },
        },
      },
      palette: isDarkMode ? darkTheme : lightTheme,
      typography: {
        useNextVariants: true,
        geode: geodeColors,
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
    });
  }, [themeMode]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
