/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from 'redux-starter-kit';

export interface PressLoginPayload {
  username: string;
  password: string;
}

export interface PressLoginNotOkPayload {
  statusText: string;
  status: number;
}

export type FormStatus = 'init' | 'busy' | 'success' | 'error' | 'failed_init';

export interface FormModel {
  username: string;
  password: string;
  domain: string;
  status: FormStatus;
  errorMessage?: string;
}

export type ChangeValuesPayload = Partial<FormModel>;

export interface FailedInitModel {
  statusText: string;
  status: number;
}

const initialState = {
  options: undefined as ShowLoginOkPayload | undefined,
  form: {
    username: '',
    password: '',
    domain: '',
    status: 'init',
  } as FormModel,
};

export interface ShowLoginNotOkPayload {
  status: number;
  statusText: string;
}
export interface ShowLoginOkPayload {
  clientName: string;
  domains: string[];
  reason: string;
  reasonDescription: string;
  logoUri: string;
  jwk: string;
}
export const loginSlice = createSlice({
  slice: 'login',
  initialState,
  reducers: {
    continueLogin() {},
    showLogin(draft) {},
    showLoginOk(draft, action: PayloadAction<ShowLoginOkPayload>) {
      draft.options = {
        logoUri: action.payload.logoUri,
        clientName: action.payload.clientName,
        domains: action.payload.domains,
        reason: action.payload.reason,
        reasonDescription: action.payload.reasonDescription,
        jwk: action.payload.jwk,
      };
    },
    showLoginNotOk(draft, action: PayloadAction<ShowLoginNotOkPayload>) {},
    changeValues(draft, action: PayloadAction<ChangeValuesPayload>) {
      draft.form = {
        ...draft.form,
        ...action.payload,
      };
    },
    pressLogin(draft, action: PayloadAction<PressLoginPayload>) {
      draft.form.status = 'busy';
    },
    pressLoginOk(draft) {
      return initialState;
    },
    pressLoginNotOk(draft, action: PayloadAction<PressLoginNotOkPayload>) {
      draft.form.status = 'error';
      draft.form.errorMessage = action.payload.statusText;
    },
    loginComplete() {},
  },
});
