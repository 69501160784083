import { call, put, takeLatest, select, delay, cancel } from 'redux-saga/effects';
import * as selectors from '../selectors';
import { actions } from '../reducer';
import fetchVersion from '../../../api/fetchVersion';

export function* initializeWatch() {
  while (true) {
    // / 5 minutes
    yield delay(5 * 60 * 1000);
    const currentVersion = yield select(selectors.getCurrentVersion);
    const currentStatus = yield select(selectors.getStatus);
    const pausedUntil = yield select(selectors.getPausedUntil);
    const now = new Date().getTime();

    if (currentStatus === 'outdated') {
      yield cancel();
    } else if (currentStatus === 'paused' && pausedUntil < now) {
      yield delay(pausedUntil - now);
    } else {
      const version = yield call(fetchVersion);
      if (version > currentVersion) {
        yield put(actions.newVersionReceived());
      }
    }
  }
}

function* initializeReload() {
  yield delay(500);
  window.location.reload(true);
}

export function* watchTheThing() {
  yield takeLatest(actions.begin.type, initializeWatch);
}

export function* watchReload() {
  yield takeLatest(actions.initateReload.type, initializeReload);
}
