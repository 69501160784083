import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import { Dialog } from '../../Anonymous/Dialog/Dialog';
import { ProgressButton } from '../../ProgressButton/ProgressButton';

export interface ReauthWelcomeProps {
  name: string;
  initials: string;
  application: string;
  sendNotification: any;
  message?: string;
  busy: boolean;
  logoUri: string;
  error?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '320px',
    maxWidth: '400px',
  },
  errorContainer: {
    padding: theme.spacing(1),
  },
  logo: {
    height: 60,
    width: 60,
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  avatar: {
    height: '64px',
    width: '64px',
    marginBottom: theme.spacing(0.5),
  },
  messageArea: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: theme.spacing(0.5)
  },
  header: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  hyprButton: {
    marginTop: theme.spacing(2),
  },
  hyprMessage: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    wordWrap: 'normal',
  },
}));


export const ReauthWelcome = (props: ReauthWelcomeProps) => {
  const classes = useStyles();

  const userMessage = props.message;
  const logoUri = props.logoUri;
  const error = props.error;

  return (
    <Dialog open={true}>
      <div className={classes.container}>
        {error && <div className={classes.errorContainer}><Alert variant="filled" severity="error">{error}</Alert></div>}

        <div className={classes.header}>
          {logoUri && <img src={logoUri} className={classes.logo} alt="logo" />}
          <Typography variant="h5">Hello {props.name}</Typography>
          <Typography>Welcome back to {props.application}</Typography>
        </div>
        <div className={classes.messageArea}>
          <Typography id="reauth-message" align="center" className={classes.message}>
            {userMessage}
          </Typography>

          <Typography variant="body2" align="center">
            When you are ready, press the Send Notification button to receive a HYPR notification on your phone.
          </Typography>
        </div>
        <div className={classes.hyprMessage}>
          <div className={classes.actionBar}>
            <ProgressButton
              className={classes.hyprButton}
              onClick={props.sendNotification}
              loading={props.busy}
              success={false}
            >
              Send Notification
            </ProgressButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
