import jsencrypt from "jsencrypt";
import * as jose from "jose";

export interface EncryptOptions {
  key: string | Record<string, any>;
  content: string | Record<string, any>;
}

export const encrypt = async (options: EncryptOptions) => {
  try {
    const encrypt = new jsencrypt();
    encrypt.setPublicKey(options.key as string);

    let normContent = options.content;

    if (typeof normContent === "object") {
      normContent = JSON.stringify(normContent);
    }

    return encrypt.encrypt(normContent) as string;
  } catch (error) {}
};
