import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useApi = (action: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action());
  }, [dispatch, action]);
};
