import { Palette } from '@material-ui/core/styles/createPalette';
import { geodeColors } from '@eog/geode-colors';

export const lightTheme: Partial<Palette> = {
  type: 'light',
  primary: {
    main: geodeColors.blueViolet[70],
    light: geodeColors.blue[60],
    dark: geodeColors.blueViolet[80],
    contrastText: '',
  },
  secondary: {
    main: geodeColors.green[50],
    light: geodeColors.green[40],
    dark: geodeColors.green[60],
    contrastText: geodeColors.gray[5],
  },
  background: {
    // default: geodeColors.gray[0],
    default: '#ffffff',
    // paper: geodeColors.gray[5],
    paper: 'rgb(251, 251, 253)',
  },
  text: {
    primary: geodeColors.coolGray[95],
    secondary: geodeColors.coolGray[70],
    disabled: geodeColors.gray[40],
    hint: geodeColors.gray[80],
  },
};
