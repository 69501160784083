import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../../store/reducers';
import { UxFailedInit } from './UxFailedInit';

export const FailedInit = (): JSX.Element => {
  const error = useSelector((state: IState) => state.interaction.error);

  return <UxFailedInit title={error || 'Failed to Resume Session'} />;
};
