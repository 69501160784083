import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button, { ButtonProps } from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    wrapper: {
      display: 'flex',
      position: 'relative',
    },
    buttonSuccess: {
      // backgroundColor: green[500],
      '&:hover': {
        // backgroundColor: green[700],
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    checkSuccess: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export interface ProgressButtonProps {
  loading: boolean;
  success: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
}

export function ProgressButton(props: ProgressButtonProps & ButtonProps) {
  const classes = useStyles();
  const { loading, success, children, className, ...buttonProps } = props;
  const buttonClassname = clsx(classes.button, {
    [classes.buttonSuccess]: success,
  });

  return (
    <div className={className || classes.root}>
      <div className={classes.wrapper}>
        <Button variant="outlined" size="large" className={buttonClassname} style={{ flex: 1 }} disabled={loading || success} {...buttonProps}>
          {children || 'Accept Terms'}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        {success && <CheckIcon className={classes.checkSuccess} />}
      </div>
    </div>
  );
}
