/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, select, getContext } from 'redux-saga/effects';
import { loginSlice } from './slices';
import { IState } from '../../../store/reducers';
import { PUBLIC_URL } from 'env';
import { appSlice } from 'components/App/slice';
import * as encryption from '../../../encryption';

function* onPressLogin() {
  try {
    // @ts-ignore
    const token = yield call(grecaptcha.enterprise.execute, '6LecD0YjAAAAADiK63FboF_FbByHweoqUGs7iLqV', { action: 'LOGIN' } )

    // @ts-ignore
    const interactionId = yield select((state: IState) => state.interaction.interactionId);
    const { username, password, domain } = yield select((state: IState) => state.login.form);
    const { jwk } = yield select((state: IState) => state.login.options);

    const domainUrl = new URL(window.location.href);
    const browserDomain = `${domainUrl.protocol}//${domainUrl.host}`;

    const encPassword: string = yield call(encryption.encrypt, { 
      key: jwk, 
      content: {
        domain: browserDomain,
        password: password,
      } 
    });

    const response: Response = yield call(fetch, `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/login`, {
      method: 'POST',
      redirect: 'manual',
      headers: {
        'X-Username': username,
        'X-Password': encPassword as string,
        'X-Identity-Provider': domain,
        'Access-Control-Request-Headers': 'Location, X-Status-Message',
        'X-Recaptcha-Token': token
      },
    });

    if (!response.ok) {
      const location = response.headers.get('Location');

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (response.ok) {
      const location = response.headers.get('Location');

      if (location) {
        window.location.href = location
        return;
      }

      const history = yield getContext('history');
      const historyUrl = `/v2/interaction/${interactionId}/mfa/options`;
      history.push(historyUrl);
      yield put(loginSlice.actions.pressLoginOk());
    } else {
      const { status } = response;
      const statusText = response.headers.get('X-Status-Message') || 'An unexpected error occurred';
      yield put(
        loginSlice.actions.pressLoginNotOk({
          status,
          statusText,
        })
      );
    }
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* onShowLogin() {
  try {
    const interactionId = yield select((state: IState) => state.interaction.interactionId);
    const response: Response = yield call(fetch, `${PUBLIC_URL}/iapi/v2/interaction/${interactionId}/login`, {
      method: 'GET',
    });

    if (!response.ok) {
      const location = response.headers.get('Location');

      if (location) {
        window.location.href = encodeURI(location);
      }
    }

    if (response.ok) {
      const json = yield call([response, 'json']);
      const { domains, reason, reasonDescription, clientName, logoUri, jwk } = json;

      yield put(
        loginSlice.actions.showLoginOk({
          logoUri,
          clientName,
          domains,
          reason,
          reasonDescription,
          jwk,
        })
      );
    } else {
      const { statusText, status } = response;
      yield put(
        loginSlice.actions.showLoginNotOk({
          status,
          statusText,
        })
      );
    }
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* onContinueLogin() {
  const interactionId = yield select((state: IState) => state.interaction.interactionId);

  const endpoint = `${PUBLIC_URL}/v2/interaction/${interactionId}`
  window.location.href = endpoint;

  const history = yield getContext('history');
  history.push(endpoint);
}

export function* watchLoginSagas() {
  yield takeLatest(loginSlice.actions.pressLogin, onPressLogin);
  yield takeLatest(loginSlice.actions.showLogin, onShowLogin);
  yield takeLatest(loginSlice.actions.continueLogin, onContinueLogin);
}
