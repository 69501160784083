import { spawn, ForkEffect } from 'redux-saga/effects';
import {
  watchFetchMe as session,
  watchForSessionExpiration as sessionExpiration,
} from '../components/SessionWall/saga';
import { watchReload as palaPalaReload, watchTheThing as palaPalaWatch } from '../components/PalaPala/sagas';
import { watchInteractionSagas } from '../components/Anonymous/sagas';
import { watchLoginSagas } from '../components/Anonymous/Login/sagas';
import { watchMultiFactorSagas } from '../components/Anonymous/MultiFactor/sagas';
import { passwordlessSagas } from '../components/Passwordless/sagas';
import { watchApproveSagas } from 'components/Approve/sagas';

import * as interactionSagas from '../v3/data/interaction/sagas';
import * as interactionFlowSagas from '../v3/data/interaction-flow/sagas';
import * as reauthSagas from '../components/Reauth/sagas';

export default function* root(): Generator<ForkEffect, void, unknown> {
  yield spawn(watchApproveSagas);
  yield spawn(passwordlessSagas);
  yield spawn(session);
  yield spawn(sessionExpiration);
  yield spawn(palaPalaReload);
  yield spawn(palaPalaWatch);
  yield spawn(watchInteractionSagas);
  yield spawn(watchLoginSagas);
  yield spawn(watchMultiFactorSagas);

  yield spawn(reauthSagas.watchGetInteraction);
  yield spawn(reauthSagas.watchHyprPush);
  yield spawn(reauthSagas.watchHyprWait);

  yield spawn(interactionSagas.watchInteractionDetailsRequested);
  yield spawn(interactionSagas.watchInteractionCompletionRequested);
  yield spawn(interactionFlowSagas.watchFlowStepSuccess);
  yield spawn(interactionFlowSagas.watchUserAuthOptionsRequested);
  yield spawn(interactionFlowSagas.watchAuthOptionChanged);
  yield spawn(interactionFlowSagas.watchUserValidationRequested);
  yield spawn(interactionFlowSagas.watchPasswordAuthenticationRequested);
  yield spawn(interactionFlowSagas.watchHyprMagicLinkRequested);
  yield spawn(interactionFlowSagas.watchHyprClientRequested);
  yield spawn(interactionFlowSagas.watchHyprAuthenticationRequested);
  yield spawn(interactionFlowSagas.watchMfaOptionsRequested);
  yield spawn(interactionFlowSagas.watchMfaStartRequested);
  yield spawn(interactionFlowSagas.watchMfaVerificationRequested);
}
