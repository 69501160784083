import { Palette } from '@material-ui/core/styles/createPalette';
import { geodeColors } from '@eog/geode-colors';

export const darkTheme: Partial<Palette> = {
  type: 'dark',
  primary: {
    main: geodeColors.blue[30],
    light: geodeColors.blue[30],
    dark: geodeColors.blue[40],
    contrastText: geodeColors.gray[5],
  },
  secondary: {
    main: geodeColors.red[50],
    light: geodeColors.red[40],
    dark: geodeColors.red[60],
    contrastText: geodeColors.gray[5],
  },
  background: {
    default: geodeColors.coolGray[100],
    paper: geodeColors.coolGray[85],
  },
  text: {
    primary: geodeColors.gray[5],
    secondary: geodeColors.gray[10],
    disabled: geodeColors.gray[40],
    hint: geodeColors.gray[10],
  },
};
