import { getContext, call, select } from 'redux-saga/effects';
import get from 'lodash/get';

export function* getApiCall(funcPath: string, ...args: any): any {
  const api = yield getContext('api');

  const apiFunc = get(api, funcPath);
  const interactionId = yield select((state) => state.v3interaction.interactionId);
  const result = yield call(apiFunc, interactionId, ...args);

  return result;
}
