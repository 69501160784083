import React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { IconText } from '../components/IconText';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';

export const InteractionSuccess = (): React.ReactElement => {
  return (
    <InteractionStep>
      <InteractionStepTitle title="Authentication Complete" />
      <InteractionStepContent verticalCenter horizontalCenter>
        <IconText Icon={VerifiedUserIcon}>Redirecting to your original app</IconText>
      </InteractionStepContent>
    </InteractionStep>
  );
};
