import { createSlice, PayloadAction } from "redux-starter-kit";

export interface ReauthState {
  error?: string;
  hyprWaiting: boolean;
  interactionId?: string;
  view: "welcome" | "confirmation";
  loaded: boolean;
  busy: boolean;
  details: {
    interactionId: string;
    user: {
      name: string;
      initials: string;
      firstName: string;
      lastName: string;
    };
    client: {
      name: string;
      homepage: string;
    };
  };
}

const initialState: any = {
  view: "welcome",
  hyprWaiting: false,
  loaded: false,
  details: {},
  busy: false,
  error: undefined,
};

export const reauthSlice = createSlice({
  slice: "reauth",
  initialState,
  reducers: {
    loadInteraction(draft, action: any) {
      draft.details = action.details;
      draft.interactionId = action.payload.interactionId;
    },
    loadInteractionSuccess(draft, action: any) {
      draft.loaded = true;
      draft.details = action.payload;
    },
    loadInteractionFailure(draft, action: any) {
      draft.loaded = false;
    },
    startHyprPush(draft, action: any) {
      draft.busy = true;
    },
    hyprPushSuccess(draft, action: any) {
      draft.busy = false;
      draft.view = "confirmation";
    },
    hyprPushFailure(draft, action: any) {
      draft.error = action.payload.error;
    },
    waitForHyprConfirmation(draft, action: any) {
      draft.hyprWaiting = true;
    },
    hyprConfirmationSuccess(draft, action: any) {
      draft.hyprWaiting = false;
    },
    hyprConfirmationFailure(draft, action: any) {
      draft.hyprWaiting = false;
    },
  },
});
