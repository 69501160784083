import React from 'react';
import { Dialog } from '../Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
    margin: 0,
  },
  nextStep: {
    height: '2rem',
  },
}));

export const UxNoMfaOptionsFound = (): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.container,
      }}
    >
      <DialogTitle id="alert-dialog-title">Multi-Factor is Required</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          No MFA options are configured. Please contact your administrator.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
