import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { LanguageSelector } from './LanguageSelector';
import { ThemeSelector } from '../../Theme/ThemeSelector';

const useStyles = makeStyles(() => {
  return {
    container: {
      width: 450,
      padding: '0px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});

export const MainOptions = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LanguageSelector />
      <ThemeSelector />
    </div>
  );
};
