import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: { mode: 'dark' },
  reducers: {
    changeTheme: (draft, action) => {
      draft.mode = action.payload.mode;
    },
  },
});
