import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useButton } from '../hooks';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
    },
    buttonProgress: {
      color: theme.palette.primary.light,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

export const ProgressButton = (props: any): React.ReactElement => {
  const { step, enterNext, requiredPaths, loadingPath, action, ...rest } = props;

  const { loading, disabled, onClick } = useButton(step, requiredPaths, loadingPath, action, enterNext);
  const classes = useStyles();

  const buttonLabel = rest.label || 'Next';

  return (
    <div className={classes.container}>
      <Button
        {...rest}
        variant="outlined"
        size="large"
        disabled={disabled || rest.disabled}
        onClick={onClick}
        className={classes.button}
      >
        {buttonLabel}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};
