import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import SwipeableViews from 'react-swipeable-views';

import { InteractionSteps } from '../types';
import { UserIdentifierStep } from '../InteractionSteps/UserIdentifier';
import { AuthenticatePassword } from '../InteractionSteps/AuthenticatePassword';
import { MFAOptions } from '../InteractionSteps/MFAOptions';
import { HyprAuth } from '../InteractionSteps/HyprAuth';
import { HyprAuthWait } from '../InteractionSteps/HyprAuthWait';
import { HyprDownload } from '../InteractionSteps/HyprDownload';
import { HyprQRCode } from '../InteractionSteps/HyprQRCode';
import { HyprSmsRegistrationWait } from '../InteractionSteps/HyprSmsRegistrationWait';
import { OTPVerification } from '../InteractionSteps/OTPVerification';
import { DuoPushWait } from '../InteractionSteps/DuoPushWait';
import { MagicLinkWait } from '../InteractionSteps/MagicLinkWait';
import { InteractionCompletionWait } from '../InteractionSteps/InteractionCompletionWait';
import { InteractionSuccess } from '../InteractionSteps/InteractionSuccess';

const stepMap: { [key: string]: any } = {
  [InteractionSteps.USER_IDENTIFIER]: UserIdentifierStep,
  [InteractionSteps.AUTHENTICATE]: AuthenticatePassword,
  [InteractionSteps.AUTHENTICATE_HYPR]: HyprAuth,
  [InteractionSteps.HYPR_AUTH_WAIT]: HyprAuthWait,
  [InteractionSteps.MFA_OPTIONS]: MFAOptions,
  [InteractionSteps.HYPR_DOWNLOAD]: HyprDownload,
  [InteractionSteps.HYPR_QR_CODE]: HyprQRCode,
  [InteractionSteps.HYPR_SMS_REGISTRATION_WAIT]: HyprSmsRegistrationWait,
  [InteractionSteps.OTP_VERIFICATION]: OTPVerification,
  [InteractionSteps.DUO_PUSH_WAIT]: DuoPushWait,
  [InteractionSteps.MAGIC_LINK_WAIT]: MagicLinkWait,
  [InteractionSteps.INTERACTION_COMPLETION_WAIT]: InteractionCompletionWait,
  [InteractionSteps.INTERACTION_COMPLETE]: InteractionSuccess,
};

const renderStep = (key: string) => {
  const Component = stepMap[key];
  return <Component key={key} />;
};

export const Interaction = (): React.ReactElement | null => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = useSelector((state: any) => state.interactionFlow.steps);
  const currentStep = useSelector((state: any) => state.interactionFlow.currentStep);
  const currentStepIndex = steps.indexOf(currentStep);

  if (steps.length === 0) {
    return null;
  }

  const style = isSmallDevice ? { height: '100vh', width: '100%' } : { height: 'auto', width: '450px' };
  const slideStyle = isSmallDevice ? { height: '100vh', width: '100%' } : { height: 'auto', width: '450px' };

  return (
    <SwipeableViews slideStyle={slideStyle} style={style} index={currentStepIndex} disabled={true} animateHeight>
      {steps.map((key: string) => renderStep(key))}
    </SwipeableViews>
  );
};
