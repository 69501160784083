import React, { useEffect } from 'react';
import { UxApprove } from './UxApprove';
import { useSelector, useDispatch } from 'react-redux';
import { approveSlice } from './slice';
import { IState } from 'store/reducers';

export const Approve = (): JSX.Element => {
  const { status, message } = useSelector((state: IState) => state.approve);
  const dispatch = useDispatch();

  useEffect(() => {
    const action = approveSlice.actions.showApprove();
    dispatch(action);
  }, [dispatch]);

  return <UxApprove status={status} message={message} />;
};
