import { put } from 'redux-saga/effects';
import { getApiCall } from './get-api-call';
import { interactionFlowSlice } from '../interaction-flow/slice';
import { interactionDataSlice } from '../interaction-data/slice';
import { notificationSlice } from '../notifications/slice';
import validator from 'validator';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export function* getFlowResult(funcPath: string, ...args: any): any {
  const result = yield getApiCall(funcPath, ...args);

  if (result.error) {
    if (funcPath === 'mfa.verify') {
      yield put(interactionDataSlice.actions.setData({ key: 'interactionData.data.verificationCode', value: '' }));
    }

    yield put(interactionFlowSlice.actions.flowStepFailure(result));

    yield put(
      notificationSlice.actions.enqueueSnackbar({
        message: result.errorMessage,
        options: {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
      })
    );

    if (result.location) {
      yield delay(2000);
      window.location.href = encodeURI(result.location);
    }
  } else {
    const flowResult = result.flow || result;
    yield put(interactionFlowSlice.actions.flowStepSuccess(flowResult));
  }

  return result;
}
