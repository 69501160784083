import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../store/reducers';
import { multiFactorSlice } from './slices';
import { UxCodeEntry } from './UxCodeEntry';

const messages: { [s: string]: () => string } = {
  already_verified_code: () => `This code has already been verified. Close the browser and try again.`,
  max_attempt_exceeded: () => `The maximum number of attempts has been exceeded. Close the browser and try again.`,
  incorrect_code: () => `The code was incorrect. Try again.`,
  expired_code: () => `The code has expired. Close the browser and try again.`,
  valid: () => `The code is correct.`,
};

export const TOTPCodeEntry = (): JSX.Element => {
  const { busy, errorMessage, reason } = useSelector((state: IState) => state.multiFactor.otp);
  const interaction = useSelector((state: IState) => state.interaction.interaction);

  // const title = `${name} requires Multi-Factor Authentication`;

  const message = errorMessage && messages[errorMessage] && messages[errorMessage]();
  const enabled = errorMessage ? !['max_attempt_exceeded', 'expired_code'].includes(errorMessage) : true;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(multiFactorSlice.actions.codeEntryShown());
  }, [dispatch]);
  const handleChange = useCallback(
    (value: string) => {
      const action = multiFactorSlice.actions.changeOtpValues({
        code: value,
      });
      dispatch(action);
    },
    [dispatch]
  );

  const handlePressCancel = useCallback(() => {
    const action = multiFactorSlice.actions.pressCancel();
    dispatch(action);
  }, [dispatch]);

  const handlePressSubmitCode = useCallback(() => {
    const action = multiFactorSlice.actions.pressSubmitCode();
    dispatch(action);
  }, [dispatch]);

  const handleResendCode = useCallback(() => {
    const action = multiFactorSlice.actions.resendMfaCode();
    dispatch(action);
  }, [dispatch]);

  return (
    <UxCodeEntry
      showResend={false}
      title={reason}
      helperText={message}
      onChange={handleChange}
      enabled={enabled}
      busy={busy}
      onResendCode={handleResendCode}
      onPressCancel={handlePressCancel}
      onPressSubmitCode={handlePressSubmitCode}
    />
  );
};