import React, { useCallback, useEffect } from 'react';
import { UxMultiFactor } from './UxMultiFactor';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from 'store/reducers';
import { multiFactorSlice } from './slices';

export const MultiFactor = (): JSX.Element => {
  // const client = useSelector((state: IState) => state.interaction.details && state.interaction.details.client);
  const { options, selectedIndex, busy, reasonDescription, nextStepFailedMessage } = useSelector(
    (state: IState) => state.multiFactor.form
  );

  const message = 'Select from the options below:';
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (value: string) => {
      const action = multiFactorSlice.actions.pressOption({
        value,
      });
      dispatch(action);
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    const action = multiFactorSlice.actions.pressCancel();
    dispatch(action);
  }, [dispatch]);

  const sendMfaCode = useCallback(() => {
    const action = multiFactorSlice.actions.sendMfaCode();
    dispatch(action);
  }, [dispatch]);

  useEffect(() => {
    dispatch(multiFactorSlice.actions.multiFactorShown());
  }, [dispatch]);

  return (
    <UxMultiFactor
      busy={busy}
      options={options}
      title="Additional Authentication Required"
      message={message}
      selectedIndex={selectedIndex}
      errorMessage={nextStepFailedMessage}
      onChange={handleChange}
      onPressCancel={handleCancel}
      onPressNextStep={sendMfaCode}
    />
  );
};
