import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './shared/i18n/i18n';
import { store } from './store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { StylesProvider } from '@material-ui/core/styles';
import { generateClassName } from './class-name-generator';
import './index.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debug = require('debug');

debug.enable();

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);
