import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useField } from '../hooks';

const useStyles = makeStyles(() => {
  return {
    textField: {
      '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
        {
          WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
          backgroundColor: '#2E3842',
          boxShadow: `0 0 0 1000px #2E3842 inset`,
          backgroundClip: 'content-box !important',
          borderRadius: 0,
        },
    },
  };
});

export const Field = (props: any): React.ReactElement => {
  const { path, action, errorPath, ...rest } = props;
  const fieldProps = useField(path, action, errorPath);
  const inputRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef.current.focus();
      }
    }, 250);
  }, []);

  return (
    <TextField
      inputRef={inputRef}
      {...rest}
      {...fieldProps}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      className={classes.textField}
    />
  );
};
