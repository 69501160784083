import { createSlice } from '@reduxjs/toolkit';
import { reducer } from '../../hooks/use-field';

export const interactionDataSlice = createSlice({
  name: 'interactionData',
  initialState: { data: {} },
  reducers: {
    setData: reducer,
  },
});
