import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAuthOptions = (key: string) => {
  const authOptions = useSelector((state: any) => state.interactionFlow.data.authOptions);
  const normAuthOptions = authOptions || [];
  const keys = useMemo(() => normAuthOptions.map((option: any) => option.key), [normAuthOptions]);

  return keys.includes(key);
};
