import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as SessionReducer } from '../components/SessionWall/reducer';
import { reducer as PalaPalaReducer } from '../components/PalaPala/reducer';
import { interactionSlice } from '../components/Anonymous/slices';
import { loginSlice } from '../components/Anonymous/Login/slices';
import { multiFactorSlice } from '../components/Anonymous/MultiFactor/slices';
import { passwordlessSlice } from '../components/Passwordless/slice';
import { appSlice } from 'components/App/slice';
import { approveSlice } from 'components/Approve/slice';
import { combineReducers } from 'redux';

import { reauthSlice } from 'components/Reauth/slice';
import { themeSlice } from '../Theme/slice';
import v3Interaction from '../v3/data/interaction';
import v3IntearctionFlow from '../v3/data/interaction-flow';
import { interactionDataSlice } from '../v3/data/interaction-data';
import { notificationSlice } from '../v3/data/notifications/slice';

export const history = createBrowserHistory();

export const reducers = combineReducers({
  approve: approveSlice.reducer,
  app: appSlice.reducer,
  passwordless: passwordlessSlice.reducer,
  multiFactor: multiFactorSlice.reducer,
  login: loginSlice.reducer,
  interaction: interactionSlice.reducer,
  session: SessionReducer,
  palaPala: PalaPalaReducer,
  router: connectRouter(history),
  theme: themeSlice.reducer,
  v3interaction: v3Interaction.interactionSlice.reducer,
  interactionFlow: v3IntearctionFlow.interactionFlowSlice.reducer,
  interactionData: interactionDataSlice.reducer,
  notifications: notificationSlice.reducer,
  reauth: reauthSlice.reducer,
});

// type which will be used throughout the app
export type IState = ReturnType<typeof reducers>;
