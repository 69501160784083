import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    control: {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  };
});

export const RadioSelect = (props: any) => {
  const classes = useStyles();
  const { options, key, onChange, ...rest } = props;

  return (
    <RadioGroup {...rest} onChange={onChange} value={rest.value}>
      {options.map((option: any) => {
        return (
          <FormControlLabel
            classes={{
              label: classes.control,
            }}
            id={option.key}
            key={option.key}
            value={option.key}
            label={option.text}
            control={<Radio color="primary" />}
          />
        );
      })}
    </RadioGroup>
  );
};
