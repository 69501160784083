import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconGroup } from './IconGroup';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      fontSize: 80,
      color: theme.palette.text.primary,
    },
    secondaryIcon: {
      fontSize: 70,
      color: theme.palette.text.primary,
    },
    message: {
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
  };
});

export const IconText = (props: any) => {
  const classes = useStyles();

  const Icon = props.Icon;
  const SecondaryIcon = props.SecondaryIcon;

  const hasMultipleIcons = Icon && SecondaryIcon;

  return (
    <div className={classes.container}>
      {!hasMultipleIcons && <Icon className={classes.icon} />}
      {hasMultipleIcons && (
        <IconGroup>
          <Icon className={classes.icon} />
          <SecondaryIcon className={classes.secondaryIcon} />
        </IconGroup>
      )}
      <Typography align="center" variant="h6" className={classes.message}>
        {props.children}
      </Typography>
    </div>
  );
};
