import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { InteractionAlternativesList } from '../components/InteractionAlternativesList';
import { LinkButton } from '../components/LinkButton';
import { ProgressButton } from '../components/ProgressButton';
import { SimpleVerificationCode } from '../components/SimpleVerificationCode';

import { InteractionSteps } from '../types';
import { interactionDataSlice } from '../data/interaction-data';
import { interactionFlowSlice } from '../data/interaction-flow';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    message: {
      marginTop: 10,
      fontWeight: 300,
      color: theme.palette.text.primary,
    },
  };
});

export const OTPVerification = (): React.ReactElement => {
  const { t } = useTranslation('v3Translations');
  const classes = useStyles();
  const dispatch = useDispatch();

  const code = useSelector((state: any) => state.interactionData.data.verificationCode);
  const disabled = !code || code.length !== 6;

  const handleComplete = useCallback(
    (code: string) => {
      dispatch(interactionDataSlice.actions.setData({ key: 'interactionData.data.verificationCode', value: code }));
    },
    [dispatch]
  );

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('otp_verification_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <SimpleVerificationCode code={code} onChange={handleComplete} />
        <Typography variant="h6" className={classes.message} align="center">
          {t('otp_verification_message')}
        </Typography>
        <InteractionAlternativesList>
          <LinkButton label="Resend Code" action={interactionFlowSlice.actions.mfaStartRequested} />
        </InteractionAlternativesList>
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.OTP_VERIFICATION}
          id="submit-otp-verification"
          disabled={disabled}
          action={interactionFlowSlice.actions.mfaVerificationRequested}
          label={t('otp_verification_button_label')}
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
