import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EOGFlame from '@eog/geode-icons/EOGFlame';
import get from 'lodash/get';

const useStyles = makeStyles((theme: Theme) => {
  return {
    title: {
      color: theme.palette.text.primary,
    },
    container: {
      paddingTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 50,
      '& > *:last-child': {
        marginTop: theme.spacing(1),
      },
    },
    logo: {
      color: theme.palette.primary.light,
    },
    logoTitle: {
      paddingTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(22),
    },
    message: {
      fontWeight: 300,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary,
    },
  };
});

export const InteractionStepTitle = (props: any): JSX.Element => {
  const classes = useStyles();
  const details = useSelector((state: any) => state.v3interaction.details);

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <EOGFlame fontSize="inherit" className={classes.logo} />
        <Typography className={classes.title}>{get(details, 'client.name')}</Typography>
      </div>
      <Typography id="interaction-message" className={classes.message} variant="h6">
        {props.title}
      </Typography>
    </div>
  );
};
