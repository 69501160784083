import { createSlice, PayloadAction } from 'redux-starter-kit';

export interface LoadInteractionPayload {
  interactionId: string;
}

export interface FailedInitModel {
  statusText: string;
  status: number;
}
export interface FailedLoginModel {
  statusText: string;
  status: number;
}

export interface InteractionModel {
  interactionId?: string;
  error?: string;
  interaction?: InteractionOkPayload;
}
export interface InteractionNotOkPayload {
  status: number;
  statusText: string;
}
export interface InteractionOkPayload {
  reason: string;
  reasonDescription: string;
  interaction: string;
  interactionDescription: string;
  loginHint: string;
  loginMessage: string;
  cancelUrl: string;
  forgotPasswordLink: string;
  landingLinks: string;
  readOnlyUsername: boolean;
  notAccountHolderUrl: string;
  accountDomain?: string;
  logoUri?: string;
}
const initialState: InteractionModel = {};

export const interactionSlice = createSlice({
  slice: 'interaction',
  initialState,
  reducers: {
    loadInteraction(draft, action: PayloadAction<LoadInteractionPayload>) {
      draft.interactionId = action.payload.interactionId;
      draft.interaction = undefined;
      draft.error = undefined;
    },
    interactionNotOk(draft, action: PayloadAction<InteractionNotOkPayload>) {
      draft.error = action.payload.statusText;
    },
    interactionOk(draft, action: PayloadAction<InteractionOkPayload>) {
      draft.interaction = action.payload;
    },
  },
});
