// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// const root = `${window.PUBLIC_URL.replace('/v2', '/v3')}`;

export const send = async (url: string, fetchOptions: any) => {
  const response = await fetch(url, fetchOptions);
  const json = await response.json();

  return json;
};

export const sendInteraction = async (interactionId: string, path: string, fetchOptions = {}) => {
  const url = `${''}/iapi/v3/interaction/${interactionId}${path}`;

  const finalOptions = {
    headers: { 'Content-Type': 'application/json' },
    ...fetchOptions,
    body: JSON.stringify((fetchOptions as any).body),
  };

  const result = send(url, finalOptions);

  return result;
};
