import React from 'react';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '../components/LinkButton';
import { HyprIcon } from '../components/HyprIcon';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionAlternativesList } from '../components/InteractionAlternativesList';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { IconText } from '../components/IconText';
import { ProgressButton } from '../components/ProgressButton';

import { useApi } from '../hooks';
import { interactionFlowSlice } from '../data/interaction-flow';
import { InteractionSteps } from '../types';

export const HyprAuth = (): React.ReactElement => {
  const { t } = useTranslation('v3Translations');
  useApi(interactionFlowSlice.actions.userAuthOptionsRequested);

  const handleClick = () => {
    return interactionFlowSlice.actions.authOptionChanged({
      key: InteractionSteps.AUTHENTICATE,
    });
  };

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('hypr_auth_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <IconText Icon={HyprIcon} SecondaryIcon={PhoneIcon}>
          {t('hypr_auth_message')}
        </IconText>
        <InteractionAlternativesList>
          <LinkButton id="use-password-authentication" label="Use Password Authentication" action={handleClick} />
        </InteractionAlternativesList>
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.AUTHENTICATE_HYPR}
          id="submit-hypr-auth-request"
          action={interactionFlowSlice.actions.hyprAuthenticationRequested}
          label={t('hypr_auth_button_label')}
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
