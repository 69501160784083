import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

interface RadioReturnItems {
  options: Array<any>;
  key: string;
  currentValue: any;
  onChange: (event: any) => any;
}

export const useRadio = (
  optionsPath: string,
  keyPath: string,
  action: any,
  getCurrentValue?: any
): RadioReturnItems => {
  const dispatch = useDispatch();

  const options = useSelector((state: any) => get(state, optionsPath));
  const key = useSelector((state: any) => get(state, keyPath));

  let currentValue = null;

  if (typeof getCurrentValue === 'function' && Array.isArray(options)) {
    currentValue = getCurrentValue(options, key);
  }

  const onChange = useCallback((event: any) => dispatch(action({ key: keyPath, value: event.target.value })), [
    dispatch,
    keyPath,
    action,
  ]);

  return {
    options: options || [],
    key: key || '',
    currentValue: currentValue,
    onChange,
  };
};
