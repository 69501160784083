import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import useEvent from './use-event';

interface useButtonResult {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
}

export const useButton = (
  step: string,
  requiredPaths: Array<string>,
  loadingPath: string,
  action: () => void,
  enterToContinue = true
): useButtonResult => {
  const dispatch = useDispatch();

  const items = useSelector((state: any) =>
    isEmpty(requiredPaths) ? [] : requiredPaths.map((path) => get(state, path))
  );

  const currentStep = useSelector((state: any) => state.interactionFlow.currentStep);
  const loading = useSelector((state: any) => (isEmpty(loadingPath) ? false : get(state, loadingPath)));
  const disabled = items.some((item: any) => isEmpty(item)) || loading;

  const onClick = useCallback(() => dispatch(action()), [dispatch, action]);

  useEvent('keyup', (event: any) => {
    const isEnterKey = event.keyCode === 13;

    if (isEnterKey && enterToContinue && !disabled && currentStep === step) {
      onClick();
    }
  });

  return {
    loading,
    disabled,
    onClick,
  };
};
