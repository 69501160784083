import { takeLatest, put, cancel } from 'redux-saga/effects';
import { interactionSlice } from './slice';
import { interactionFlowSlice } from '../interaction-flow/slice';
import { interactionDataSlice } from '../interaction-data/slice';
import { getApiCall } from '../utils';
import { InteractionSteps } from '../../types';
import get from 'lodash/get';

function* onInteractionDetailsRequested() {
  const result = yield getApiCall('interaction.details');

  const loginHint = get(result.details, 'params.login_hint');

  if (loginHint) {
    yield put(
      interactionDataSlice.actions.setData({
        key: 'interactionData.data.userIdentifier',
        value: loginHint,
      })
    );
  }

  yield put(interactionSlice.actions.interactionDetailsReceived(result));
  yield put(interactionFlowSlice.actions.flowStepSuccess(result.flow));
}

function* onInteractionCompletionRequested(action: any) {
  if (action.type === interactionFlowSlice.actions.flowStepSuccess.toString()) {
    const { next } = action.payload;

    if (!next.includes(InteractionSteps.INTERACTION_COMPLETION_WAIT)) {
      yield cancel();
    }
  }

  const result = yield getApiCall('interaction.complete');

  if (!result.error) {
    window.location = result.location;
    setTimeout(window.location.reload);
  }
}

export function* watchInteractionDetailsRequested() {
  yield takeLatest(interactionSlice.actions.interactionDetailsRequested, onInteractionDetailsRequested);
}

export function* watchInteractionCompletionRequested() {
  const actions = [interactionFlowSlice.actions.flowStepSuccess, interactionFlowSlice.actions.skipHyprRegistration];
  yield takeLatest(actions, onInteractionCompletionRequested);
}
