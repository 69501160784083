import { sendInteraction } from '../send';

export const details = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/details');
  return result;
};

export const complete = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/complete', { method: 'POST' });
  return result;
};
