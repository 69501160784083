import React from 'react';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    backgroundColor: 'transparent',
  },
}));

export const Dialog = (props: DialogProps): JSX.Element => {
  const classes = useStyles();
  return (
    <MuiDialog
      {...props}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    />
  );
};
