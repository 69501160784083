import React, { useCallback, ChangeEvent, memo, useRef, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import Typography from '@material-ui/core/Typography/Typography';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import Icon from '@material-ui/core/Icon/Icon';
import * as icons from '../Icons';
import clsx from 'clsx';
import { ProgressButton } from '../ProgressButton/ProgressButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  links: {},
  textField: {
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: 'inherit',
    },
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
        backgroundColor: '#2E3842',
        boxShadow: `0 0 0 1000px #2E3842 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    textAlign: 'center',
  },
  button: {},
  buttonGroup: {
    lineHeight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },
  errorMessage: {
    alignSelf: 'center',
  },
  didNotGetCode: {
    lineHeight: theme.typography.pxToRem(44),
    cursor: 'pointer',
    alignSelf: 'center',
    textDecoration: 'underline',
  },
}));

export interface CodeVerifyUxProps {
  errorMessage?: string;
  message: string;
  code?: string;
  onPressSubmit: () => void;
  onPressDidNotGetCode: () => void;
  onChangeValue: (key: string, value: string) => void;
  loading: boolean;
}

export const CodeVerifyUx = memo((props: CodeVerifyUxProps) => {
  const { onChangeValue, onPressSubmit, onPressDidNotGetCode, code, message, errorMessage, loading } = props;
  const classes = useStyles({});

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      const key = e.currentTarget.id;
      onChangeValue(key, value);
    },
    [onChangeValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.keyCode;

      if (onPressSubmit && value === 13) {
        onPressSubmit();
      }
    },
    [onPressSubmit]
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title} dangerouslySetInnerHTML={{ __html: message }}></Typography>
      {errorMessage && (
        <FormHelperText className={classes.errorMessage} error>
          {errorMessage}
        </FormHelperText>
      )}
      <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
        <OutlinedInput
          className={classes.textField}
          autoFocus
          inputRef={inputRef}
          labelWidth={0}
          id="code"
          placeholder="your code"
          startAdornment={
            <Icon aria-label="code">
              <icons.Phone />
            </Icon>
          }
          value={code}
          onChange={handleChangeValue}
          onKeyDown={handleKeyDown}
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            maxLength: 6,
            'aria-label': 'weight',
            autoComplete: 'one-time-code',
            type: 'tel',
            autoCapitalize: 'off',
            spellCheck: false,
            autoCorrect: 'off',
          }}
        />
      </FormControl>
      <div className={classes.links}>
        <Typography variant="caption" className={classes.didNotGetCode} onClick={onPressDidNotGetCode}>
          Did not get the code?
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <ProgressButton id="submit" loading={loading} success={false} onClick={onPressSubmit}>
          Submit
        </ProgressButton>
      </div>
    </div>
  );
});

CodeVerifyUx.displayName = 'CodeVerifyUx';
