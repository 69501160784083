import React, { useCallback, ChangeEvent, useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import Typography from '@material-ui/core/Typography/Typography';
import Icon from '@material-ui/core/Icon/Icon';
import Alert from '@material-ui/lab/Alert';
import isEmpty from 'lodash/isEmpty';

import * as icons from '../../Icons';

import clsx from 'clsx';
import { FormStatus } from './slices';
import { ProgressButton } from '../../ProgressButton/ProgressButton';
import { Dialog } from '../Dialog/Dialog';

const getLinks = (linkString: string) => {
  if (!linkString) {
    return [];
  }

  const links = linkString.split('\n');

  return links.reduce((result: Array<any>, current: string) => {
    const index = current.indexOf(':');
    const title = current.slice(0, index);
    const url = current.slice(index + 1, current.length);

    if (!title || !url) {
      return result;
    }

    result.push({ title, url });
    return result;
  }, []);
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(320),
    maxWidth: theme.typography.pxToRem(400),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBrand: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 40,
    width: 40,
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
  textField: {
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
        backgroundColor: '#2E3842',
        boxShadow: `0 0 0 1000px #2E3842 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0.2),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  message: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    alignSelf: 'center',
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    alignSelf: 'center',
  },
}));

const mapOfDomains: { [k: string]: string } = {
  eog: 'Employee',
};

export interface UxLoginProps {
  status: FormStatus;
  errorMessage?: string;
  title: string;
  message?: string;
  username?: string;
  password?: string;
  domain?: string;
  domains?: string[];
  forgotPasswordLink?: string;
  landingLinks?: string;
  readOnlyUsername?: boolean;
  onPressLogin: () => void;
  onChangeValue: (key: string, value: string) => void;
  logoUri?: string;
  notAccountHolderUrl?: string;
  loginHint?: string;
}

export const UxLogin = (props: UxLoginProps): JSX.Element => {
  const {
    username,
    password,
    onChangeValue,
    onPressLogin,
    status,
    message,
    domain,
    domains,
    errorMessage,
    title,
    logoUri,
    readOnlyUsername,
    landingLinks,
    notAccountHolderUrl,
    loginHint,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [caplocksActive, setCaplocksActive] = useState(false);

  const handleChangeButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const value = e.currentTarget.value;
      onChangeValue('domain', value);
    },
    [onChangeValue]
  );

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      const key = e.currentTarget.id;
      onChangeValue(key, value);
    },
    [onChangeValue]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.keyCode;
      if (onPressLogin && value === 13) {
        onPressLogin();
      }
      setCaplocksActive(e.getModifierState('CapsLock'));
    },
    [onPressLogin]
  );

  const noUsername = isEmpty(username);
  const loginHintNotAvailable = !isEmpty(loginHint);

  const disableLoginInput = !isEmpty(loginHint) && readOnlyUsername === true;
  const autoFocusUsername = noUsername || loginHintNotAvailable
  const autoFocusPassword = disableLoginInput || !isEmpty(username);

  return (
    <Dialog fullScreen={fullScreen} open={true}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerBrand}>
            {logoUri && <img src={logoUri} className={classes.logo} alt="logo" />}
            <Typography id="title" className={classes.title}>
              {title}
            </Typography>
          </div>
          <Typography id="login-message" className={classes.message}>
            {message}
          </Typography>
        </div>
        {errorMessage && (
          <Alert id="login-error-message" variant="filled" severity="error">
            {errorMessage}
          </Alert>
        )}
        {caplocksActive && (
          <Alert variant="filled" severity="warning">
            Caps Lock On!
          </Alert>
        )}
        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            disabled={disableLoginInput}
            autoFocus={autoFocusUsername}
            labelWidth={0}
            id="username"
            placeholder="Username"
            endAdornment={
              <Icon aria-label="username">
                <icons.Username />
              </Icon>
            }
            value={username}
            onChange={handleChangeValue}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              autoCapitalize: 'off',
              autoCorrect: 'off',
              autoComplete: 'off',
              spellCheck: false,
              autoSave: 'off',
            }}
          />
        </FormControl>

        <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
          <OutlinedInput
            autoFocus={autoFocusPassword}
            labelWidth={0}
            id="password"
            placeholder="Password"
            type="password"
            onChange={handleChangeValue}
            onKeyDown={handleKeyDown as any}
            endAdornment={
              <Icon aria-label="password">
                <icons.Password />
              </Icon>
            }
            value={password}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </FormControl>

        {domains && domains.length > 1 && (
          <ButtonGroup
            id="domain-button-group"
            fullWidth
            className={clsx(classes.margin)}
            aria-label="contained primary button group"
          >
            {domains.map((x) => {
              const selected = x === domain;
              if (selected) {
                return (
                  <Button key={x} value={x} variant="contained" color="primary" onClick={handleChangeButton}>
                    {mapOfDomains[x] || x}
                  </Button>
                );
              } else {
                return (
                  <Button key={x} value={x} onClick={handleChangeButton}>
                    {mapOfDomains[x] || x}
                  </Button>
                );
              }
            })}
          </ButtonGroup>
        )}

        {notAccountHolderUrl && (
          <a className={classes.link} key="not-account-holder" href={notAccountHolderUrl}>
            Not {username}?
          </a>
        )}

        {landingLinks &&
          getLinks(landingLinks).map((link) => {
            return (
              <a className={classes.link} key={link.title} href={link.url}>
                {link.title}
              </a>
            );
          })}

        <div className={classes.buttonContainer}>
          <ProgressButton
            onClick={onPressLogin}
            id="login_btn"
            loading={status === 'busy'}
            success={status === 'success'}
          >
            Login
          </ProgressButton>
        </div>
      </div>
    </Dialog>
  );
};

export default UxLogin;
