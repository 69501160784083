import { sendInteraction } from '../send';

export const getOptions = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/mfa/options');
  return result;
};

export const start = async (interactionId: string, mfaKey: string) => {
  const result = await sendInteraction(interactionId, '/mfa/start', {
    method: 'POST',
    body: { mfaKey },
  });

  return result;
};

export const wait = async (interactionId: string, mfaKey: string) => {
  const result = await sendInteraction(interactionId, '/mfa/wait', {
    method: 'POST',
    body: { mfaKey },
  });

  return result;
};

export const verify = async (interactionId: string, code: string) => {
  const result = await sendInteraction(interactionId, '/mfa/verify', {
    method: 'POST',
    body: { code },
  });
  return result;
};
