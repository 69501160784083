import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography/Typography';
import Alert from '@material-ui/lab/Alert';

import { ProgressButton } from '../../ProgressButton/ProgressButton';
import { Dialog } from '../Dialog/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(320),
    maxWidth: theme.typography.pxToRem(400),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.down(325)]: {
      margin: 0,
      padding: theme.spacing(1),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerBrand: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 50,
    width: 50,
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  link: {
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
  textField: {
    ['& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active']:
      {
        WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
        backgroundColor: '#2E3842',
        boxShadow: `0 0 0 1000px #2E3842 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0.2),
    fontSize: theme.typography.pxToRem(20),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  message: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
  buttonGroup: {
    marginTop: theme.spacing(1),
  },
  errorMessage: {
    alignSelf: 'center',
  },
}));

export interface UxLoginProps {
  onContinueLogin: any;
  logoUri?: string;
  error?: string;
}

export const UxDevice = (props: UxLoginProps): JSX.Element => {
  const {
    onContinueLogin,
    error,
    logoUri,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog fullScreen={fullScreen} open={true}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerBrand}>
            {logoUri && <img src={logoUri} className={classes.logo} alt="logo" />}
            <Typography id="title" className={classes.title}>
              New Device Detected
            </Typography>
          </div>
          <Typography id="login-message" className={classes.message}>
            It looks like you're signing in from a new device. Please verify your identity to register this device. 
          </Typography>
        </div>
        {error && (
          <Alert id="login-error-message" variant="filled" severity="error">
            {error}
          </Alert>
        )}  

        <div className={classes.buttonContainer}>
          <ProgressButton
            onClick={onContinueLogin}
            id="new-device-continue"
            loading={false}
            success={false}
          >
            Continue
          </ProgressButton>
        </div>
      </div>
    </Dialog>
  );
};

export default UxDevice;
