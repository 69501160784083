import { createTransform } from 'redux-persist';
import get from 'lodash/get';

export const interactionDataTransform = createTransform(
  (inboundState: any) => {
    const userIdentifier = get(inboundState, 'data.userIdentifier');

    if (userIdentifier) {
      return {
        data: {
          userIdentifier: userIdentifier,
        },
      };
    }

    return { data: {} };
  },
  null,
  { whitelist: ['interactionData'] }
);
