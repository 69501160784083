import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog } from '../Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export interface UxMultiFactorProps {
  title: string;
  onPressCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
    margin: 0,
  },
  cancelButton: {
    color: 'red'
  }
}));

export const UxHyprPush = (props: UxMultiFactorProps) => {
  const { title, onPressCancel } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.container,
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Push Notification has been sent. Please confirm the request in HYPR.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} color="primary" onClick={onPressCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
