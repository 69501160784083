import { PayloadAction, createSlice } from 'redux-starter-kit';

export enum PalaPalaStatus {
  paused = 'paused',
  current = 'current',
  unknown = 'unknown',
  outdated = 'outdated',
}

interface TimestampAction {
  time: number;
}

const initialState = {
  status: PalaPalaStatus.unknown,
  currentVersion: 0,
  pausedUntil: 0,
};

const palaPalaSlice = createSlice({
  slice: 'palaPala',
  initialState,
  reducers: {
    begin: (state, action: PayloadAction<TimestampAction>) => {
      state.status = PalaPalaStatus.current;
      state.currentVersion = action.payload.time;
    },
    wasPaused: (state, action: PayloadAction<TimestampAction>) => {
      state.status = PalaPalaStatus.paused;
      state.pausedUntil = action.payload.time;
    },
    newVersionReceived: state => {
      state.status = PalaPalaStatus.outdated;
    },
    initateReload: state => {},
  },
});

export const actions = palaPalaSlice.actions;

export const reducer = palaPalaSlice.reducer;
