import { createStore, applyMiddleware } from 'redux';
import { composeReducers } from 'immer-reducer';
import { reducers, history, IState } from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import { startLogging } from '@eog/react-logging';
import packageJson from '../../package.json';
import { makeReduxLoggerMiddleware, OptionsModel } from '@eog/react-logging/lib/redux';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import awsExports from '@eog/react-logging/lib/aws-exports';
import { makeApmTraceMonitor, EOGEnvironments } from '@eog/redux-saga-tracer';
import Amplify, { Analytics } from 'aws-amplify';
import * as api from '../v3/api';
Amplify.configure(awsExports);

const loggingEnvironment = (process.env['REACT_APP_DATA_ENV'] || 'local') as EOGEnvironments;
const APPNAME = 'APPNAME';

const getUsername = () => {
  const state: IState = store.getState();
  return state.session.user ? state.session.user.username : undefined;
};
const sagaMonitor = makeApmTraceMonitor({
  serviceName: `${APPNAME}-${loggingEnvironment}`,
  environment: loggingEnvironment,
  getUsername,
});
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
  context: {
    api,
    history,
  },
});

startLogging({
  Analytics,
  getUsername,
  getOtherAttributes: () => ({
    appName: `${APPNAME}-${loggingEnvironment}`,
    appVersion: packageJson.version,
  }),
});

const middlewares = [sagaMiddleware, makeReduxLoggerMiddleware({} as OptionsModel), routerMiddleware(history)];

const reducer = composeReducers(reducers);

export const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
(window as any).store = store;
sagaMiddleware.run(rootSaga);
