import { interactionFlowSlice } from './slice';
import * as interactionFlowSagas from './sagas';

export { interactionFlowSlice } from './slice';
// export * as interactionFlowSagas from './sagas';

export default {
  interactionFlowSlice,
  interactionFlowSagas,
};
