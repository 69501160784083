import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        v3Translations: {
          invalid_user: 'Username not found',
          invalid_user_domain: 'Too many accounts...please contact support',
          auth_credentials_failure: 'Invalid credentials',
          user_identifier_title: 'Sign In',
          user_identifier_label: 'Username or Phone',
          user_identifier_button_label: 'Next',
          password_title: 'Enter Password',
          password_label: 'Password',
          password_button_label: 'Next',
          auth_options_label: 'Other Auth Options',
          hypr_auth_title: 'Authenticate with Hypr',
          hypr_auth_message:
            'Make sure you have your mobile device that has the HYPR app installed and registered for passwordless login',
          hypr_auth_button_label: 'Send Request',
          otp_verification_title: 'Enter Verification Code',
          otp_verification_message: 'Please enter the verification code sent to one of your devices',
          otp_verification_button_label: 'Verify Code',
          duo_wait_title: 'Duo Confirmation',
          duo_wait_message: 'Please confirm through the Duo app on your mobile device',
          hypr_magiclink_wait_title: 'Register HYPR Client',
          hypr_magiclink_wait_message:
            'Please follow the link sent to your mobile device to register HYPR passwordless',
          hypr_auth_wait_title: 'Hypr Auth Confirmation',
          hypr_auth_wait_message: 'Please complete the auth request in the HYPR app on your mobile device',
          hypr_download_title: 'HYPR Registration',
          hypr_download_message: "We'll send you a link via text message to register HYPR Passwordless",
          hypr_qr_code_title: 'Hypr Registration',
          hypr_qr_code_message:
            'Please open the HYPR app on your mobile device and follow the instructions to scan the QR Code above',
        },
        translations: {
          sign_in: 'Sign in',
          username_placeholder_text: 'Email or Phone',
          user_not_found: 'The username may be incorrect. Enter a different one.',
          too_many_users_found: `There's a conflict with this account please contact support.`,
          incorrect_username_password: `The username or password was incorrect`,
          password_placeholder: `Password`,
          password_title: `Password`,
          password_next_button_text: `Sign in`,
          hypr_setup_next_button: `Show QR Code`,
          hypr_instructions: `Download the Hypr App from the App or Play store.`,
          hypr_instructions_part2: `Once you're ready to scan the QR code, click 'SHOW QR CODE' below.`,
          hypr_title: `Setup Passwordless Login`,
          hypr_skip_button: `skip`,
          hypr_instructions_scan: `Open the Hypr App and Scan the QR Code below:`,
          hypr_scan_result_waiting: `waiting for scan`,
          hypr_scan_result_registering: `Registering`,
          hypr_scan_result_ok: `Finish passwordless setup`,
          previous_button_text: `previous`,
          hypr_waiting_to_verify: `waiting`,
          passwordless_title: `Passwordless Login`,
          passwordless_wait_text: `Waiting for passwordless verification.`,
          passwordless_resend_request: `Resend request`,
          sign_in_title: 'Sign In',
        },
      },
      es: {
        v3Translations: {
          invalid_user: 'Nombre de usuario no válido',
          invalid_user_domain: 'Demasiadas cuentas ... póngase en contacto con el servicio de asistencia',
          auth_credentials_failure: 'Credenciales no válidas',
          user_identifier_title: 'Registrarse',
          user_identifier_label: 'Nombre de usuario o teléfono',
          user_identifier_button_label: 'Próximo',
          password_title: 'Introducir la contraseña',
          password_label: 'Contraseña',
          password_button_label: 'Próximo',
          auth_options_label: 'Opciones de autenticación',
          hypr_auth_title: 'Autenticar con Hypr',
          hypr_auth_message:
            'Asegúrese de tener su dispositivo móvil que tenga la aplicación HYPR instalada y registrada para iniciar sesión sin contraseña',
          hypr_auth_button_label: 'Enviar petición',
          otp_verification_title: 'Ingrese el código de verificación',
          otp_verification_message: 'Ingrese el código de verificación enviado a su dispositivo móvil',
          otp_verification_button_label: 'Código de verificación',
          duo_wait_title: 'Confirmación de Duo',
          duo_wait_message: 'Confirma a través de la aplicación Duo en tu dispositivo móvil.',
          hypr_auth_wait_title: 'Confirmación Hypr Auth',
          hypr_auth_wait_message:
            'Complete la solicitud de autenticación en la aplicación HYPR en su dispositivo móvil',
          hypr_download_title: 'Registro HYPR',
          hypr_download_message:
            'Descargue la aplicación HYPR en su dispositivo móvil y continúe con el proceso de registro',
          hypr_qr_code_title: 'Registro HYPR',
          hypr_qr_code_message: 'Utilice la aplicación HYPR para escanear el código QR y registrar su dispositivo',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
