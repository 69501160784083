/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { Dialog } from '../Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useTheme, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Alert from '@material-ui/lab/Alert';
import { ProgressButton } from '../../ProgressButton/ProgressButton';

export interface UxCodeEntryProps {
  helperText?: string;
  title?: string;
  enabled: boolean;
  busy: boolean;
  onChange: (value: string) => void;
  onPressSubmitCode: () => void;
  onPressCancel: () => void;
  onResendCode: () => void;
  showResend?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
    margin: 0,
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
        backgroundColor: '#2E3842',
        boxShadow: `0 0 0 1000px #2E3842 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  resend: {
    alignSelf: 'flex-end',
  },
  cancelButton: {
    color: 'red'
  }
}));

export const UxCodeEntry = (props: UxCodeEntryProps): JSX.Element => {
  const { helperText, onChange, onPressSubmitCode, onPressCancel, onResendCode, enabled, busy } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const showResend = typeof props.showResend === 'undefined' ? true : props.showResend;

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<{}>) => {
    const target = e.target as HTMLInputElement;
    onChange(target.value);
  }, [onChange]);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onPressSubmitCode();
  }, [onPressSubmitCode]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.container,
      }}
    >
      <form onSubmit={handleKeyUp}>
        <DialogTitle id="alert-dialog-title">Enter the Code</DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id="alert-dialog-description">Enter the 6 digit Code Below:</DialogContentText>
          {/* <InputBase /> */}
          {helperText && (
            <Alert className={classes.alert} id="login-error-message" variant="filled" severity="error">
              {helperText}
            </Alert>
          )}
          <OutlinedInput
            className={classes.textField}
            autoFocus
            inputRef={inputRef}
            id="input-code"
            labelWidth={0}
            placeholder="Your OTP Code"
            inputProps={{
              maxLength: 6,
              type: 'tel',
              autoComplete: 'one-time-code',
              autoCapitalize: 'off',
              spellCheck: false,
              autoCorrect: 'off',
            }}
            onChange={handleChange}
            disabled={!enabled}
          />
        </DialogContent>
        <DialogActions>
          {showResend && <Button autoFocus color="primary" onClick={onResendCode}>
            Resend Code
          </Button>}
          <Button className={classes.cancelButton} color="primary" onClick={onPressCancel} disabled={!enabled}>
            Cancel
          </Button>

          <ProgressButton
            id="submit-btn"
            color="primary"
            onClick={onPressSubmitCode}
            disabled={!enabled}
            loading={busy}
            success={false}
          >
            Submit Code
          </ProgressButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
