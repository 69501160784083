import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ReauthNotificationPrompt } from '../components/NotificationPrompt';
import { reauthSlice } from '../slice';
import { IState } from '../../../store/reducers';

export const NotificationPromptView = (): any => {
  const dispatch = useDispatch();

  const waitingForHypr = useSelector((state: any) => state.reauth.hyprWaiting);
  const busy = useSelector((state: IState) => state.reauth.busy);

  useEffect(() => {
    const action = reauthSlice.actions.waitForHyprConfirmation();
    dispatch(action);
  }, []);

  const sendNotification = useCallback(() => {
    const action = reauthSlice.actions.startHyprPush();
    dispatch(action);
  }, [dispatch]);

  return <ReauthNotificationPrompt busy={busy} disabled={waitingForHypr} sendNotification={sendNotification} />;
};
