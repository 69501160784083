import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';

import { Field } from '../components/Field';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { ProgressButton } from '../components/ProgressButton';
import { InteractionSteps } from '../types';

import { interactionDataSlice } from '../data/interaction-data';
import { interactionFlowSlice } from '../data/interaction-flow';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      [theme.breakpoints.up('md')]: {
        height: 400,
      },
    },
  };
});

export const UserIdentifierStep = (): React.ReactElement => {
  const classes = useStyles();

  const { t } = useTranslation('v3Translations');
  const details = useSelector((state: any) => state.v3interaction.details);

  const usernameDisabled = get(details, 'interaction.disabledUsername');
  const message = get(details, 'interaction.message');

  return (
    <InteractionStep className={classes.container}>
      <InteractionStepTitle title={message} />
      <InteractionStepContent verticalCenter sparse>
        <Field
          id="user-identifier"
          label={t('user_identifier_label')}
          path="interactionData.data.userIdentifier"
          errorPath="interactionFlow.errors.username"
          action={interactionDataSlice.actions.setData}
          InputProps={{
            disabled: usernameDisabled,
            autoCapitalize: 'off',
            autoCorrect: 'off',
            autoSave: 'off',
            autoComplete: 'off',
            spellCheck: false,
            endAdornment: (
              <InputAdornment position="end">
                <PersonIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.USER_IDENTIFIER}
          label={t('user_identifier_button_label')}
          id="submit-user-identifier"
          requiredPaths={['interactionData.data.userIdentifier']}
          loadingPath="interactionFlow.loading"
          action={interactionFlowSlice.actions.userValidationRequested}
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
