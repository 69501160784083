import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import set from 'lodash/set';

export const useField = (path: string, action: any, errorPath: string) => {
  const { t } = useTranslation('v3Translations');
  const dispatch = useDispatch();

  const errorValue = useSelector((state: any) => get(state, errorPath));
  const fieldValue = useSelector((state: any) => get(state, path));

  const onChange = useCallback(
    (event: any) => {
      const value = event.target.value;
      dispatch(action({ key: path, value }));
    },
    [dispatch, path, action]
  );

  return {
    value: fieldValue || '',
    onChange,
    error: !!errorValue,
    helperText: t(errorValue),
  };
};

export const reducer = (draft: any, action: any) => {
  const { key, value } = action.payload;

  const pathSegments = key.split('.');
  const writePath = pathSegments.slice(1, pathSegments.length).join('.');

  set(draft, writePath, value);
};
