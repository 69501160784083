/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice, PayloadAction } from 'redux-starter-kit';

export interface StartInteractionPayload {
  interactionId: string;
}
export interface ParamsModel {
  phoneNumber?: string;
  code?: string;
  [key: string]: string | undefined;
}
export interface ReceivedStartInteractionPayload {
  error?: string;
  error_message?: string;
  clientName: string;
  logoUri?: string;
  startUri?: string;
}

export interface InteractionModel {
  clientName: string;
  logoUri?: string;
  error?: string;
  error_description?: string;
  startUri?: string;
  loginMessage?: string;
}
export interface ChangeValuePayload {
  key: keyof ParamsModel;
  value: string;
}

export interface ReceivedVerifyPayload {
  error?: string;
  error_message?: string;

  expires: string;
  message: string;
}

export interface VerifyModel {
  expires: string;
  message: string;
  error?: string;
  error_message?: string;
}

export const passwordlessSlice = createSlice({
  slice: 'passwordless',
  initialState: {
    stage: 'entry',
    params: {} as ParamsModel,
    interactionId: null as string | null,
    interaction: null as InteractionModel | null,
    verify: null as VerifyModel | null,
    loading: false,
    errorMessage: undefined,
  },
  reducers: {
    startInteraction(state, action: PayloadAction<StartInteractionPayload>): void {
      state.interactionId = action.payload.interactionId;
    },
    receivedStartInteraction(state, action: PayloadAction<ReceivedStartInteractionPayload>): void {
      state.params = {};
      state.interaction = {
        ...action.payload,
      };
      state.verify = null;
    },
    receivedVerify(state, action: PayloadAction<ReceivedVerifyPayload>): void {
      state.verify = {
        ...action.payload,
      };
    },
    changeValue(state, action: PayloadAction<ChangeValuePayload>): void {
      const { key } = action.payload;
      const { value } = action.payload;
      if (!state.params) {
        throw new Error('interaction is null');
      }
      state.params[key] = value;
    },
    sendCode(state): void {
      state.loading = true;
    },
    sendCodeError(state, action): void {
      state.loading = false;
      state.errorMessage = action.payload.message;
    },
    sendCodeComplete(state): void {
      state.loading = false;
    },
    didNotGetCode(state): void {
      state.verify = null;
    },
    verifyCode(state): void {
      state.loading = true;
    },
    verifyCodeComplete(state): void {
      state.loading = false;
    },
  },
});
