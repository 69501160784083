import React, { useCallback } from 'react';
import { UxDuoPush } from './UxDuoPush';
import { multiFactorSlice } from '../MultiFactor/slices';
import { useDispatch } from 'react-redux';

export const DuoPush = (): JSX.Element => {
  const dispatch = useDispatch();
  const title = 'Waiting for Verification';

  const handlePressCancel = useCallback(() => {
    const action = multiFactorSlice.actions.pressCancel();
    dispatch(action);
  }, [dispatch]);

  return <UxDuoPush title={title} onPressCancel={handlePressCancel} />;
};
