import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

const useStyles = makeStyles(() => {
  return {
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '0px 15px',
      flexDirection: 'column',
    },
    verticalAlignContainer: {
      justifyContent: 'flex-start',
    },
    horizontalAlignContainer: {
      alignItems: 'center',
    },
    sparse: {
      // paddingTop: 55,
    },
  };
});

export const InteractionStepContent = (props: any): React.ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const containerClassName = clsx(classes.container, {
    [classes.verticalAlignContainer]: props.verticalCenter && !isSmallDevice,
    [classes.horizontalAlignContainer]: props.horizontalCenter,
    [classes.sparse]: props.sparse,
  });

  return <div className={containerClassName}>{props.children}</div>;
};
