import React from 'react';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import { useTranslation } from 'react-i18next';

import { HyprIcon } from '../components/HyprIcon';
import { IconText } from '../components/IconText';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';

export const HyprSmsRegistrationWait = (): React.ReactElement => {
  const { t } = useTranslation('v3Translations');

  return (
    <InteractionStep>
      <InteractionStepTitle title={t('hypr_magiclink_wait_title')} />
      <InteractionStepContent verticalCenter horizontalCenter>
        <IconText Icon={HyprIcon} SecondaryIcon={PhoneIcon}>
          {t('hypr_magiclink_wait_message')}
        </IconText>
      </InteractionStepContent>
    </InteractionStep>
  );
};
