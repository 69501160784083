import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import { useTranslation } from 'react-i18next';

import { Field } from '../components/Field';
import { InteractionStep } from '../components/InteractionStep';
import { InteractionStepTitle } from '../components/InteractionStepTitle';
import { InteractionStepContent } from '../components/InteractionStepContent';
import { InteractionAlternativesList } from '../components/InteractionAlternativesList';
import { InteractionStepActions } from '../components/InteractionStepActions';
import { ProgressButton } from '../components/ProgressButton';
import { LinkButton } from '../components/LinkButton';

import { useApi, useAuthOptions } from '../hooks';
import { interactionDataSlice } from '../data/interaction-data';
import { interactionFlowSlice } from '../data/interaction-flow';
import { InteractionSteps } from '../types';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      [theme.breakpoints.up('md')]: {
        height: 400,
      },
    },
  };
});

export const AuthenticatePassword = (): React.ReactElement => {
  useApi(interactionFlowSlice.actions.userAuthOptionsRequested);
  const classes = useStyles();

  const { t } = useTranslation('v3Translations');
  const domainMeta = useSelector((state: any) => state.interactionFlow.data.domainMeta);
  const canUseHypr = useAuthOptions(InteractionSteps.AUTHENTICATE_HYPR);

  const handleClick = () => {
    return interactionFlowSlice.actions.authOptionChanged({
      key: InteractionSteps.AUTHENTICATE_HYPR,
    });
  };

  return (
    <InteractionStep className={classes.container}>
      <InteractionStepTitle title={t('password_title')} />
      <InteractionStepContent verticalCenter sparse>
        <Field
          id="password"
          label={t('password_label')}
          path="interactionData.data.password"
          errorPath="interactionFlow.errors.password"
          action={interactionDataSlice.actions.setData}
          InputProps={{
            type: 'password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            autoSave: 'off',
            endAdornment: (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <InputAdornment>
                <LockIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
        <InteractionAlternativesList>
          {domainMeta && domainMeta.forgotPasswordLink && <LinkButton label="Forgot Password?" />}
          {canUseHypr && (
            <LinkButton id="use-hypr-authentication" label="Use Hypr Authentication" action={handleClick} />
          )}
        </InteractionAlternativesList>
      </InteractionStepContent>
      <InteractionStepActions>
        <ProgressButton
          step={InteractionSteps.AUTHENTICATE}
          id="submit-password"
          label={t('password_button_label')}
          requiredPaths={['interactionData.data.password']}
          loadingPath="interactionFlow.loading"
          action={interactionFlowSlice.actions.passwordAuthenticationRequested}
        />
      </InteractionStepActions>
    </InteractionStep>
  );
};
