import { detect } from 'detect-browser';

const isSupportedBrowser = (): boolean => {
  const browser = detect();

  if (!browser) {
    return true;
  }

  return browser.name !== 'ie';
};

export default isSupportedBrowser;
