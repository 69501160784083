import { passwordlessSlice, StartInteractionPayload } from './slice';
import { takeLatest, select, call, put } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { IState } from 'store/reducers';
import { appSlice } from 'components/App/slice';
/* eslint-disable @typescript-eslint/no-var-requires */
const debug = require('debug')('Passwordless:sagas');

function* handleStartInteraction(action: PayloadAction<StartInteractionPayload>) {
  try {
    const interactionId = yield select((state: IState) => state.passwordless.interactionId);
    const response = yield call(fetch, `/iapi/passwordless/1/${interactionId}/details`, {
      credentials: 'include',
    });

    debug('handleStartInteraction:response', response);
    const body = yield call([response, 'json']);
    debug('handleStartInteraction:body', body);

    const nextAction = passwordlessSlice.actions.receivedStartInteraction(body);
    yield put(nextAction);
  } catch (error) {
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* handleSendCode() {
  try {
    const interactionId = yield select((state: IState) => state.passwordless.interactionId);
    if (!interactionId) {
      return;
    }
    const phoneNumber = yield select(
      (state: IState) => state.passwordless.interaction && state.passwordless.params.phoneNumber
    );

    if (!phoneNumber) {
      return;
    }

    const response = yield call(fetch, `/iapi/passwordless/1/${interactionId}/sendSMS`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
      }),
    });

    debug('handleSendCode:response', response);

    const body = yield call([response, 'json']);
    debug('handleSendCode:body', body);

    if (body.error) {
      yield put(
        passwordlessSlice.actions.sendCodeError({
          message: body.error,
        })
      );

      return;
    }

    const location = response.headers.get('Location');

    if (location) {
      window.location.href = location;
    }

    const nextAction = passwordlessSlice.actions.receivedVerify(body);
    yield put(nextAction);
    yield put(passwordlessSlice.actions.sendCodeComplete());
  } catch (error) {
    yield put(passwordlessSlice.actions.sendCodeComplete());
    yield put(appSlice.actions.sagaError({ error }));
  }
}

function* handleVerifyCode() {
  try {
    const interactionId = yield select((state: IState) => state.passwordless.interactionId);
    if (!interactionId) {
      return;
    }
    const code = yield select((state: IState) => state.passwordless.params && state.passwordless.params.code);
    if (!code) {
      return;
    }

    const response = yield call(fetch, `/iapi/passwordless/1/${interactionId}/verifyCode`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });

    debug('handleVerifyCode:response', response);
    const location = response.headers.get('location');

    if (!response.ok) {
      yield put(
        passwordlessSlice.actions.sendCodeError({
          message: response.statusText
        })
      );
    }

    if (response.ok && location) {
      window.location.href = location;
    }
  } catch (error) {
    debug('handleVerifyCode:error', error);
    yield put(passwordlessSlice.actions.verifyCodeComplete());
    yield put(appSlice.actions.sagaError({ error }));
  }
}

export const passwordlessSagas = function* () {
  yield takeLatest(passwordlessSlice.actions.startInteraction, handleStartInteraction);
  yield takeLatest(passwordlessSlice.actions.sendCode, handleSendCode);
  yield takeLatest(passwordlessSlice.actions.verifyCode, handleVerifyCode);
};
