import React from 'react';
import { DialogContent, DialogTitle, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { ProgressButton } from '../ProgressButton/ProgressButton';
import { Dialog } from '../Anonymous/Dialog/Dialog';
import { FormStatus } from 'components/Anonymous/Login/slices';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: theme.typography.pxToRem(320),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  textField: {
    '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 60px #2E3842 inset !important',
        backgroundColor: '#2E3842',
        boxShadow: `0 0 0 1000px #2E3842 inset`,
        backgroundClip: 'content-box !important',
        borderRadius: 0,
      },
  },
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    // alignSelf: 'center',
  },
  message: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(14),
  },
  button: {},
  buttonGroup: {
    lineHeight: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    alignSelf: 'center',
  },
  errorMessage: {
    alignSelf: 'center',
  },
  success: {
    alignSelf: 'center',
  },
}));

export interface UxApproveProps {
  status: FormStatus;
  message?: string;
}

export const UxApprove = (props: UxApproveProps): JSX.Element => {
  const { status, message } = props;
  // const status = '';
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  // const handleChangeButton = useCallback(
  //   (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //     const value = e.currentTarget.value;
  //     onChangeValue('domain', value);
  //   },
  //   [onChangeValue]
  // );

  // const handleChangeValue = useCallback(
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     const value = e.currentTarget.value;
  //     const key = e.currentTarget.id;
  //     onChangeValue(key, value);
  //   },
  //   [onChangeValue]
  // );

  // const handleKeyDown = useCallback(
  //   (e: React.KeyboardEvent<HTMLInputElement>) => {
  //     const value = e.keyCode;
  //     if (onPressLogin && value === 13) {
  //       onPressLogin();
  //     }
  //   },
  //   [onPressLogin]
  // );

  return (
    <Dialog fullScreen={fullScreen} open={true}>
      <DialogTitle id="alert-dialog-title">Approve Login</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ProgressButton
          className={clsx(classes.margin, classes.button)}
          onClick={() => {
            window.close();
          }}
          id="close_btn"
          loading={status === 'busy'}
          success={false}
        >
          Close Window
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default UxApprove;
