import { sendInteraction } from '../send';

export const generateQRCode = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/hypr/generate-qr');
  return result;
};

export const registerClient = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/hypr/register');
  return result;
};

export const authenticate = async (interactionId: string, transactionOptions: any) => {
  const result = await sendInteraction(interactionId, '/hypr/authenticate', {
    method: 'POST',
    body: { transactionOptions },
  });

  return result;
};

export const sendMagicLink = async (interactionId: string) => {
  const result = await sendInteraction(interactionId, '/hypr/magic-link', {
    method: 'POST',
  });

  return result;
};
