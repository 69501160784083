import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useButton } from '../hooks';

const useStyles = makeStyles((theme) => {
  return {
    link: {
      marginTop: 15,
      paddingLeft: 10,
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      fontSize: theme.typography.pxToRem(14),
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});

export const LinkButton = (props: any): React.ReactElement => {
  const { action, requiredPaths, loadingPath, label, ...rest } = props;
  const { onClick } = useButton('blah', requiredPaths, loadingPath, action);
  const classes = useStyles();

  return (
    <Typography {...rest} onClick={onClick} className={classes.link}>
      {label}
    </Typography>
  );
};
