/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { interactionSlice } from './slices';
import { IState } from '../../store/reducers';
import { SendError } from 'api/send';
import { fetchInteraction } from 'api/fetchInteraction';

function* onLoadInteraction() {
  try {
    const interactionId = yield select((state: IState) => state.interaction.interactionId);
    const { interaction, location} = yield call(fetchInteraction, interactionId);

    if (location) {
      console.log("LOCATION", location);
      window.location.href = location;

      return;
    }

    yield put(interactionSlice.actions.interactionOk(interaction));


  } catch (err) {
    console.log('Error', err);
    const { status, statusText } = err as SendError;
    yield put(interactionSlice.actions.interactionNotOk({ status, statusText }));
  }
}

export function* watchInteractionSagas() {
  yield takeLatest(interactionSlice.actions.loadInteraction, onLoadInteraction);
}
