import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reducer } from '../../hooks/use-field';
import { InteractionSteps } from '../../types';
import { interactionPattern } from '../utils';
import set from 'lodash/set';

interface InteractionFlowState {
  loading: boolean;
  steps: Array<string>;
  currentStep: string | null;
  errors: { [key: string]: string };
  data: { [key: string]: string };
}

interface FlowPayload {
  key: string;
  error: boolean;
  errorMessage: string;
  next: Array<string>;
  data: { [key: string]: string };
}

const initialState = {
  loading: false,
  steps: [],
  currentStep: null,
  errors: {},
  data: {},
};

const flowReducers = {
  addStep: (draft: InteractionFlowState, action: any) => {
    draft.steps.push(action.payload.step);
    draft.currentStep = action.payload.step;
  },
  removeStep: (draft: InteractionFlowState, action: any) => {
    const index = draft.steps.indexOf(action.payload.step);

    if (index !== -1) {
      draft.steps.splice(index, 1);
    }
  },
  nextStepRequested: (draft: InteractionFlowState) => {
    const stepsUpperBound = draft.steps.length - 1;
    const currentStepIndex = draft.steps.indexOf(draft.currentStep as any);

    if (currentStepIndex < stepsUpperBound) {
      draft.currentStep = draft.steps[currentStepIndex + 1];
    }
  },
  flowStepSuccess: (draft: InteractionFlowState, action: PayloadAction<FlowPayload>) => {
    const nextInteractionStep = action.payload.next;

    if (nextInteractionStep.length === 1 && nextInteractionStep[0] === draft.currentStep) {
      return;
    }

    draft.loading = false;
    const currentStepIndex = draft.steps.indexOf(draft.currentStep as any);

    draft.steps.push(...action.payload.next);
    draft.currentStep = draft.steps[currentStepIndex + 1];

    if (action.payload.key) {
      delete draft.errors[action.payload.key];
    }

    delete draft.errors[action.payload.key];

    if (action.payload.data) {
      const { data } = action.payload;

      Object.keys(data).forEach((key) => {
        set(draft, `data.${key}`, data[key]);
      });
    }
  },
  flowStepFailure: (draft: InteractionFlowState, action: PayloadAction<FlowPayload>) => {
    const result = action.payload;

    const next = action.payload.next[0];
    if (next) {
      const nextIndex = draft.steps.indexOf(next);
      draft.currentStep = draft.steps[nextIndex];

      if (next === InteractionSteps.MFA_OPTIONS) {
        draft.steps = draft.steps.slice(0, nextIndex + 1);
      }
    }

    draft.loading = false;
    draft.errors[result.key] = result.errorMessage;
  },
  loading: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  notLoading: (draft: InteractionFlowState) => {
    draft.loading = false;
  },
};

const userReducers = {
  authOptionChanged: (draft: InteractionFlowState, action: any) => {
    if (action.payload.key === InteractionSteps.AUTHENTICATE) {
      draft.steps = draft.steps.filter((step: string) => !step.includes('hypr'));
    } else {
      draft.steps = draft.steps.filter((step: string) => step !== draft.currentStep);
    }

    if (action.payload.key === InteractionSteps.AUTHENTICATE_HYPR) {
      draft.steps.push(action.payload.key);
      draft.steps.push(InteractionSteps.HYPR_AUTH_WAIT);
    } else {
      draft.steps.push(action.payload.key);
    }

    draft.currentStep = action.payload.key;
  },
  userAuthOptionsRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  userValidationRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  passwordAuthenticationRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
};

const hyprReducers = {
  hyprClientRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  hyprMagicLinkRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  hyprAuthenticationRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  skipHyprRegistration: (draft: InteractionFlowState) => {
    draft.steps.push(InteractionSteps.INTERACTION_COMPLETION_WAIT);
    draft.currentStep = InteractionSteps.INTERACTION_COMPLETION_WAIT;
  },
};

const mfaReducers = {
  mfaOptionsRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  mfaStartRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
  mfaVerificationRequested: (draft: InteractionFlowState) => {
    draft.loading = true;
  },
};

export const interactionFlowSlice = createSlice({
  name: 'interactionFlow',
  initialState,
  reducers: {
    ...userReducers,
    ...hyprReducers,
    ...flowReducers,
    ...mfaReducers,
    setData: reducer,
  },
  extraReducers: {
    '@@router/LOCATION_CHANGE': (draft: InteractionFlowState, action: any) => {
      const match = interactionPattern.match(action.payload.location.pathname);

      if (action.payload.action === 'POP') {
        const item = draft.steps.pop();

        if (item === 'hypr_auth_wait') {
          draft.steps.pop();
        }

        draft.loading = false;
      }

      if (match && match.currentStep) {
        draft.currentStep = match.currentStep;

        if (match.currentStep === InteractionSteps.USER_IDENTIFIER && draft.steps.length === 0) {
          draft.steps.push(InteractionSteps.USER_IDENTIFIER);
        }
      }
    },
  },
});
