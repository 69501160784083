import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(() => {
  return {
    select: {
      paddingLeft: 7,
      paddingRight: 7,
      fontSize: 14,
    },
  };
});

export const LanguageSelector: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const handleChange = (event: any) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      disableUnderline
      classes={{ select: classes.select }}
      id="language-selector"
      value={i18n.language}
      onChange={handleChange}
    >
      <MenuItem value="en-US">English</MenuItem>
      <MenuItem value="es">Spanish</MenuItem>
    </Select>
  );
};
